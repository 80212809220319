import React from "react";
import "../../../../styles/scss/smartpathV2/resources/resourceButton.scss";

const ResourceButton = ( {buttonText, clickFunction} ) => {
  return (
    <button className="smartpath-button" onClick={() => clickFunction()}>{buttonText}</button>
  );
};

export default ResourceButton;
