import { useMemo, useState, useEffect } from 'react';
import { Accordion } from "../../../../components/molecules/Accordion.js";
import { ReactComponent as BackgroundImage } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_01_Shadow_Text.svg";
import { ReactComponent as RedIsolatedFullColor } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_03_RedSection_Isolated_FullColor.svg";
import { ReactComponent as GreenIsolatedFullColor } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_04_GreenSection_Isolated_FullColor.svg";
import { ReactComponent as BlueIsolatedFullColor } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_05_BlueSection_Isolated_FullColor.svg";
import { ReactComponent as RedIsolatedTransparent } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_07_RedSection_Isolated_Transparent.svg";
import { ReactComponent as GreenIsolatedTransparent } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_08_GreenSection_Isolated_Transparent.svg";
import { ReactComponent as BlueIsolatedTransparent } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_09_BlueSection_Isolated_Transparent.svg";
import { ReactComponent as RedIsolatedWithLabels } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_11_RedSection_Big_Isolated_WithLabels.svg";
import { ReactComponent as GreenIsolatedWithLabels } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_12_GreenSection_Big_Isolated_WithLabels.svg";
import { ReactComponent as BlueIsolatedWithLabels } from "../../../../resources/images/smartPathEcosystemComponent/SmartPath_Ecosystem_13_BlueSection_Big_Isolated_WithLabels.svg";
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/roadmap.scss";

const Roadmap = ({ data }) => {
    const [hovered, setHovered] = useState(null);
    const [active, setActive] = useState(null);
    const isRoadmapDesktopView = (width) => width > 1275;

    // Used to throttle the mouse move listener for performance
    var timesPerSecond = 40;
    var waitUntil = 0;

    useEffect(() => {
        const ecosystemGraphicContainer = document.getElementById('ecosystem-graphic-container');
        ecosystemGraphicContainer.addEventListener('click', graphicClickHandler);
        ecosystemGraphicContainer.addEventListener('mousemove', graphicMouseMoveHandler);

        return () => {
            ecosystemGraphicContainer.removeEventListener('click', graphicClickHandler);
            ecosystemGraphicContainer.removeEventListener('mousemove', graphicMouseMoveHandler);
        };
    }, []);

    const graphicClickHandler = (event) => {
        const sectionClicked = getMouseSectionNum(event);
        setActive((prevKey) => prevKey === sectionClicked ? null : sectionClicked);
    };

    const graphicMouseMoveHandler = (event) => {
        if (Date.now() >= waitUntil) {
            setHovered(getMouseSectionNum(event));
            waitUntil = Date.now() + 1000 / timesPerSecond;
        } 
    };

    const expandCollapseCallback = (sectionNum) => {
        setActive((prevKey) => prevKey === sectionNum ? null : sectionNum);
    };

    const handleAccordionHover = (sectionNum) => {
        setHovered(sectionNum);
    };

    const getStepColor = (sectionIndex) => {
        const colorMap = ["#EB0A1E", "#86BA8C", "#50B4D7"];
        return colorMap[sectionIndex] || "#CCCCCC";
    };

    const getMouseSectionNum = (event) => {
        for (let i = 1; i <= 3; i++) {
            const svg = document.getElementById(`ecosystem-section-${i}-svg`);
            const path = document.getElementById(`ecosystem-section-${i}-polygon-isolated`);
            const point = svg.createSVGPoint();
            point.x = event.clientX;
            point.y = event.clientY;
    
            const matrix = path.getScreenCTM().inverse();
            const transformedPoint = point.matrixTransform(matrix);
    
            if(path.isPointInFill(transformedPoint)) {
                return i;
            };
        }
        return null;
    };

    const getSection = (sectionNum) => {
        if (active === null && hovered === null) {
            switch (sectionNum) {
                case 1: return <RedIsolatedFullColor />;
                case 2: return <GreenIsolatedFullColor />;
                case 3: return <BlueIsolatedFullColor />;
                default: return <RedIsolatedFullColor />;
            }
        }

        if (active === sectionNum) {
            switch (sectionNum) {
                case 1: return <RedIsolatedWithLabels />;
                case 2: return <GreenIsolatedWithLabels />;
                case 3: return <BlueIsolatedWithLabels />;
                default: return <RedIsolatedWithLabels />;
            }
        } else {
            if (hovered === sectionNum) {
                switch (sectionNum) {
                    case 1: return <RedIsolatedFullColor />;
                    case 2: return <GreenIsolatedFullColor />;
                    case 3: return <BlueIsolatedFullColor />;
                    default: return <RedIsolatedFullColor />;
                }
            } else {
                switch (sectionNum) {
                    case 1: return <RedIsolatedTransparent />;
                    case 2: return <GreenIsolatedTransparent />;
                    case 3: return <BlueIsolatedTransparent />;
                    default: return <RedIsolatedTransparent />;
                }
            }
        }
    };

    const Steps = ({steps, sectionIndex}) => {
        return (
            <div className='roadmap-steps'>
                {steps.map((step, stepIndex) => (
                    <div key={stepIndex} className='roadmap-step'>
                        <div className="roadmap-step-subtitle-container">
                            <div className="roadmap-step-icon" style={{ backgroundColor: getStepColor(sectionIndex) }}>{step.number}</div>
                            <div className='roadmap-step-subtitle'>{step.stepTitle}</div>
                        </div>
                        <div className='roadmap-step-content'>{step.stepDescription}</div>
                    </div>
                ))}
            </div>
        );
    };

    const accordionData = useMemo(() => {
        return (data?.sections || []).map((item, sectionIndex) => ({
            title: item.sectionTitle,
            body: <Steps steps={item.steps} sectionIndex={sectionIndex}/>,
            key: sectionIndex + 1
        }));
    }, [data?.sections]);

    const renderDigitalEcosystemTitle = () => (
        <div className='digital-ecosystem-title'>{data.componentTitle}</div>
    );
    
    const renderDigitalEcosystemAccordion = () => (
        <Accordion
            rows={accordionData}
            expandCollapseCallback={expandCollapseCallback}
            onMouseEnter={(key) => handleAccordionHover(key)}
            onMouseLeave={() => setHovered(null)}
            selectedItemKey={active}
        />
    );

    const renderEcosystemGraphic = () => (
        <div id='ecosystem-graphic-container'>
            <BackgroundImage />
            <div className='dynamic-sections'>
                {getSection(1)}
                {getSection(2)}
                {getSection(3)}
            </div>
        </div>
    );

    return (
        <div className="smartpath-digital-ecosystem-wrapper">
            <div className="digital-ecosystem-container">
                {isRoadmapDesktopView(window.innerWidth) ? 
                    <>
                        <div className='digital-ecosystem-left-container'>
                            {renderDigitalEcosystemTitle()}
                            {renderDigitalEcosystemAccordion()}
                        </div>
                        {renderEcosystemGraphic()}
                    </>
                : 
                    <>
                        {renderDigitalEcosystemTitle()}
                        {renderEcosystemGraphic()}
                        {renderDigitalEcosystemAccordion()}
                    </>
                }
            </div>
        </div>
    );
};

export default Roadmap;