import React, { useEffect, useMemo, useRef, useState } from "react";
import DropdownV2 from "../../../../components/molecules/DropdownV2";
import S from "../../../../styles/scss/smartpathV2/resources/previousReleaseNotes.module.scss";
import ReleaseNotesTiles from "./releaseNotesTiles";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";

const PreviousReleaseNotes = ({ title, items }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [displayedItems, setDisplayedItems] = useState(items);
  const [showViewMoreButton, setShowViewMoreButton] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    setDisplayedItems(items);
  }, [items]);

  const categories = ['All',...new Set(items.map(item => item.category))].sort((a, b) => a.localeCompare(b));

  const handleOptionAnalytics = (option) => {
    clickAnalytics({
      category: 'Resources-Release Notes',
      label: 'Dropdown Click',
      container: title,
      contentTitle: option
    });
  };
  
  const handleChange = ({ value }) => {
    if (value === "All") {
      setDisplayedItems(items);
      setShowViewMoreButton(true);
      setSelectedCategory("");
    } else {
      const newDisplayedItems = items.filter((item) => item.category === value);
      setDisplayedItems(newDisplayedItems);
      setShowViewMoreButton(false);
      setSelectedCategory(value);
    }
    handleOptionAnalytics(value);
  };

  const scrollIntoView = () => {
    const yOffset = -100;
    const y = containerRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className={S["previous-release-notes-container"]} ref={containerRef} key={displayedItems}>
      <div className={S["title-dropdown-section"]}>
        <div className={S["container-title"]}>{title}</div>
        <div className={S["dropdown-container"]}>
          <DropdownV2
            style={"white"}
            placeholder={"All"}
            options={categories}
            onValueChange={handleChange}
            value={selectedCategory}
            rootClassName={S["dropdown-control"]}
          />
        </div>
      </div>

      <ReleaseNotesTiles items={displayedItems} showViewMoreButton={showViewMoreButton} scrollIntoView={scrollIntoView} sectionTitle={title}/>
    </div>
  );
};

export default PreviousReleaseNotes;
