import { useEffect, useState } from "react";
import AppSettings from "../../../appsettings";
import { getArticleThumbnailPath } from "../../../screens/utils/articleThumbnailHelpers";
import { isDesktopView } from "../../../screens/utils/getScreenSize";
import "../../../styles/scss/articles/recommended-articles.scss";
import ContentCard from "../cards/contentCard";

// maxToShow: The most articles to display in the recommended section. Also the amount shown on desktop
// defaultToShow: The number of articles to show before clicking "SEE MORE"
export const RecommendedArticles = ({ maxToShow = 3, defaultToShow = 2, articles, articleAnalytics, seeMoreAnalitycs }) => {
    const [seeMore, setSeeMore] = useState(false);
    const [numToShow, setNumToShow] = useState(maxToShow);

    useEffect(() => {
        if(isDesktopView(window.innerWidth)) {
            setNumToShow(maxToShow);
        }
        else {
            setNumToShow(defaultToShow);
        }
    }, [window.innerWidth, maxToShow, defaultToShow]);

    useEffect(() => {
        if(!isDesktopView(window.innerWidth)) {
            if(seeMore) {
                setNumToShow(maxToShow);
                seeMoreAnalitycs('More');
            } else {
                seeMoreAnalitycs('Less');
                setNumToShow(defaultToShow);
            }
        }
    }, [seeMore]);

    const getCategoryIcon = (categories) => {
        if (categories?.find((category) => category === "video")) return "Videos.svg";
        if (categories?.find((category) => category === "news")) return "News.svg";
        return "Articles.svg";
    };

    return (
        <div className="resources-recommended-articles">
            <div className="recommended-title">
                Recommended
            </div>
            <div className="recommended-articles">
                {articles ? articles?.slice(0, numToShow).map(article => 
                    <ContentCard
                        key={article.id}
                        link={`/articles/${article.id}`}
                        title={article.title}
                        image={getArticleThumbnailPath(article)}
                        cornerIcon={
                            AppSettings.AWSImgRepo.homepage_v3 +
                            getCategoryIcon(article.categories)
                        }
                        // correct video overlay icon needed
                        overlayImage={article.categories?.includes("video") && 
                            `${AppSettings.AWSImgRepo.resources}en/playButton.png`
                        }
                        hideCornerIcon={false}
                        imageClass="aspect-ratio"
                        style="resources-recommended"
                        analytics={() => articleAnalytics(article.title, "Recommended")}
                    />
                ) : Array.from({length: numToShow}).map(i => (
                    <ContentCard loading />
                ))}
            </div>
            {!isDesktopView(window.innerWidth) && articles?.length > defaultToShow &&
                <div className="see-more-button" onClick={() => setSeeMore(!seeMore)}>
                        {seeMore ? 'SEE LESS' : 'SEE MORE'}
                </div>
            }
            
        </div>
    );
};
