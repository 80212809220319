import React, {useRef, useState} from "react";
import appsettings from "../../../../appsettings";
import "../../../../styles/scss/smartpathV2/getStarted/smartPathSalesPricing.scss";

const PricingSection = ({ data }) => {
  const containerRef = useRef(null);
  const [dragState, setDragState] = useState({ isDragging: false, startX: 0, scrollLeft: 0 });
  
  const handleMouseDown = (e) => {
    const { pageX } = e, container = containerRef.current;
    if (container) setDragState({ isDragging: true, startX: pageX - container.offsetLeft, scrollLeft: container.scrollLeft });
  };
  
  const handleMouseUpOrLeave = () => setDragState((prev) => ({ ...prev, isDragging: false }));
  
  const handleMouseMove = (e) => {
    if (!dragState.isDragging) return;
    const { pageX } = e, container = containerRef.current;
    if (container) {
      e.preventDefault();
      container.scrollLeft = dragState.scrollLeft - (pageX - container.offsetLeft - dragState.startX);
    }
  };

  const PricingCard = ({ cardTitle, cardImage, cardHeadline, cardDescription, cardBullets }) => {
    const icon = appsettings.AWSImgRepo.resources + "en/Vector.png";

    return (
      <div className="pricing-card">
        <h3 className="pricing-card-title">{cardTitle}</h3>
        <img className="pricing-card-image" src={appsettings.AWSImgRepo.smartPathV2 + cardImage} alt={cardTitle} draggable="false"/>
        <div className="pricing-card-price">{cardHeadline}</div>
        <div className="pricing-card-description">{cardDescription}</div>
        <div className="pricing-card-list-section">
          {cardBullets.map((feature, index) => (
            <div key={index} className="pricing-card-list-item">
              <img src={icon} alt={feature} className="pricing-card-list-icon"></img> 
              <span className="pricing-card-list-text">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="smartpath-pricing-component">
      <h2 className="pricing-section-title">{data.componentTitle}</h2>
      <div className="pricing-section-card-container" 
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
        onMouseMove={handleMouseMove}>
        {data.cards.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </div>
    </div>
  );
};

export default PricingSection;
