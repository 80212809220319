import React, { useContext, useState } from "react";
import AppSettings from "../../../../appsettings";
import "../../../../styles/scss/smartpathV2/sharedComponents/trainingContentTile.scss";
import { ReactComponent as ResourcesArrowIcon } from "../../../../resources/images/resources-arrow-icon.svg";
import { isDesktopView } from "../../../utils/getScreenSize";
import ResourceButton from "./resourceButton";
import { SmartPathContext } from "../../sharedComponents/smartPathContext";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
import { openPdf, openLink } from "../../sharedComponents/utils";

const watchVideoIconSrc = AppSettings.AWSImgRepo.resources + "en/resources_tile_play_button.png";
const readPdfIconSrc = AppSettings.AWSImgRepo.resources + "en/read-pdf-icon.png";
const startCourseIconSrc = AppSettings.AWSImgRepo.resources + "en/start-course-icon.png";

const TrainingContentTile = ({ data, carouselTitle }) => {
  const [isHovered, setIsHovered] = useState(false);
  const smartPathContext = useContext(SmartPathContext);

  const getButtonText = (buttonType) => {
    switch (buttonType) {
      case "pdf":
        return "OPEN PDF";
      case "video":
        return "WATCH VIDEO";
      case "course":
        return "START COURSE";
      default:
        return "";
    }
  };
  
  const renderButtons = () => {
    return (
      <>
        {data.pdfName && <ResourceButton buttonText={getButtonText('pdf')} clickFunction={() => handlePdfClick(data.pdfName, carouselTitle)}/>}
        {data.videoModalId && <ResourceButton buttonText={getButtonText('video')} clickFunction={() => handleVideoClick(carouselTitle)}/>}
        {data.startCourseLink && <ResourceButton buttonText={getButtonText('course')} clickFunction={() => handleCourseClick(data.startCourseLink, carouselTitle)}/>}
      </>
    ); 
  };

  const handleVideoClick = (container) => {
    smartPathContext.setVideoModalId(data.videoModalId);
    handleClickAnalytics(getButtonText('video'), container);
  };

  const handlePdfClick = (pdfName, carouselTitle) => {
    handleClickAnalytics(getButtonText('pdf'), carouselTitle);
    openPdf(pdfName);
  };

  const handleCourseClick = (courseLink, carouselTitle) => {
    handleClickAnalytics(getButtonText('course'), carouselTitle);
    openLink(courseLink);
  };

  const handleClickAnalytics = (buttonType, container) => {
      clickAnalytics({
        category: 'Resources-Training Content',
        label: `${buttonType || ''} Click`,
        container: container || '',
        contentTitle: data?.title || ''
      });
  };

  return (
    <div className="resources-tile" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} key={data.title}>
      <div className="resources-image-container">
        {isHovered && isDesktopView(window.innerWidth) && <div className="smartpath-overlay-container"> </div>}
        <img className="image" src={`${AppSettings.AWSImgRepo.smartPathV2}${data.image}`} alt="resource-tile"/>
        <div className="resources-tile-viewing-options">    
          {data.videoModalId && <img className="media-icon" alt="media-icon" src={watchVideoIconSrc} />}
          {data.pdfName && <img className="media-icon" alt="media-icon" src={readPdfIconSrc} />}
          {data.startCourseLink && <img className="media-icon" alt="media-icon" src={startCourseIconSrc} />}
        </div>
        {(isHovered || !isDesktopView(window.innerWidth)) &&
          <div className="smartpath-button-container">
            {renderButtons()}
          </div>
        }
        { isDesktopView(window.innerWidth) && <ResourcesArrowIcon className="resources-arrow" />}
      </div>
      <div className="resources-tile-title"> {data.title} </div>
      <div className="resources-tile-description"> {data.description} </div>
    </div>
  );
};

export default TrainingContentTile;
