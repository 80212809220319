import { postMessageToApp } from "./hybridAppHelpers";

export const isAskEngageEligible = () => {
    const userDealerCode = localStorage.getItem("dealer_code");
    const userRegionCode = localStorage.getItem("region_code");
    const userSpinId = localStorage.getItem("spin_id");
    const askEngageData = JSON.parse(localStorage.getItem("ask-engage-data"));

    // If Ask Engage is no longer in the pilot release stage OR
    // User belongs to an Ask Engage enabled Dealership OR
    // User is in an Ask Engage enabled Region OR
    // User is an Ask Engage enabled individual
    if (
        askEngageData &&
        (
            !askEngageData.pilotRelease ||
            askEngageData.enabledDealerCodes?.includes(userDealerCode) ||
            askEngageData.enabledRegionCodes?.includes(userRegionCode) ||
            askEngageData.enabledSpinIds?.includes(userSpinId)
        )
    ) {
        postMessageToApp("Ask Engage", {
            show: true,
            link: askEngageData?.askEngageUrl,
            color: askEngageData?.colorValue,
        });
        return true;
    }

    postMessageToApp("Ask Engage", { show: false });
    return false;
};

export const getAskEngageColor = () => {
    const askEngageData = JSON.parse(localStorage.getItem("ask-engage-data"));
    return askEngageData?.colorValue;
};

export const askEngageClick = () => {
    const askEngageData = JSON.parse(localStorage.getItem("ask-engage-data"));
    window.open(askEngageData?.askEngageUrl, askEngageData?.target);
};