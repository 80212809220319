import React from "react";
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/salesToolsTile.scss";
import AppSettings from "../../../../appsettings";
import applyMarkdown from '../../../utils/applyMarkdown';

const SalesToolTile = ({ title, description, label, backgroundImage }) => {
  return (
    <div className="sales-tool-tile-container">
      <div className="image-container">
        <img src={`${AppSettings.AWSImgRepo.smartPathV2}${backgroundImage}`} alt={title} />
        <div className="tool-badge-container"> {label} </div>
      </div>
      <div className="text-container">
        <div className="text-title">{title}</div>
        <div className="text-description">{applyMarkdown(description)}</div>
      </div>
    </div>
  );
};

export default SalesToolTile;