import React from 'react';
import '../../styles/css/searchBar.css';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import AppSettings from '../../appsettings.js';
import KnowledgePane from './knowledgePane';
import SearchQuickAnswers from './searchQuickAnswers';
import SearchFeedback from '../../components/molecules/search-feedback';
import SearchBodyResultContent from './searchResults/resultContent';
import SearchBarInput from '../../components/molecules/searchBarInput';
import { isDesktopView } from '../../screens/utils/getScreenSize';
import NonVehicleSearchResultPane from './nonVehicleSearchResultPane';
import { isVin, cleanString } from '../../screens/utils/helpers';
import { isAskEngageEligible, getAskEngageColor, askEngageClick } from '../utils/askEngage.js';
import { GlobalAnalytics } from '../../analytics/index.js';

const analytics = new GlobalAnalytics();

export default function SearchBarTemplate(component) {
  const updateSearchParam = (newSearchParam) => {
    const cleanedURIString = encodeURIComponent(cleanString(newSearchParam));

    if (cleanedURIString.length > 1) {
      if(isVin(newSearchParam)){
        window.location.href = `/vinLookup?${cleanedURIString}`;
      }else{
        window.location.href = `/search/?q=${cleanedURIString}`;
        component.setState({ searchParam: cleanString(newSearchParam) });
      }
    }
  };

  const updateAdvancedSearchParam = (advancedSearchParam) => {

    component.runAdvancedSearch(advancedSearchParam);

  };

  const handleAskEngageClick = () => {
    analytics.fireOnClick({
      tag: '28.2',
      container: 'Search',
      category: 'Search Results',
      label: "Ask Engage",
    });
    askEngageClick();
  };

  function searchResults() {
    const searchDisclaimers = component.state.highlightItems && component.state.highlightItems.length > 0
      ? component.state.highlightItems.map((item, index) => <DisclaimersComponent key={`disclaimer-${index}`}
        template={`${item._source.year}_${item._source.displayModelName}`}
        // eslint-disable-next-line no-param-reassign
        ref={(instance) => { component.disclaimers[index] = instance; }}
        isSearchDisclaimer={true} />) : null;

    return (
      <div className="search-page">
        <div className="search-wrap">
          <SearchResultsPage searchDisclaimers={searchDisclaimers} />
        </div>
      </div>
    );
  }

  const SearchResultsPage = (props) => {
    let searchParamText = component.state.searchParam;
    if(component.state.isAdvancedSearch == true){
      const AdvancedSearchInputText = (component.state.AdvancedSearchParamObject.allTheseWords || component.state.AdvancedSearchParamObject.thisExactWords || component.state.AdvancedSearchParamObject.anyOftheseWords || "Query")
     searchParamText = AdvancedSearchInputText
    }
    else{

    }
    return (
      <div className="search-results-page">
        <SearchBarInput
          searchParam={searchParamText}
          autoFocus={false}
          onSearch={updateSearchParam}
          advancedSearch={true}
          updateAdvancedSearchParam={updateAdvancedSearchParam}
          clickAnalytics={component.state.analyticsData && component.state.analyticsData.clickAnalytics}
          numberOfSearchResults={component.state.articleResults.length.toString()}
          showBackButton
        />
        {isAskEngageEligible() && (
          <div className='search-results-ask-engage'>
            <span className='search-results-ask-engage-question'>
              Can’t find what you’re looking for? {" "}
              <span
                className='ask-engage-text'
                onClick={handleAskEngageClick}
                style={{
                  color: getAskEngageColor()
                }}
              >
                Ask Engage
              </span>!
            </span>
          </div>
        )}
        <SearchResultsBody />
        {props.searchDisclaimers}
        <SearchFeedback location="Search Results Page" searchParam={component.state.searchParam} />
      </div>
    );
  }

  const SearchResultsBody = () => {
    return (
      <div className="search-results-body">
        {isDesktopView(window.innerWidth) ? arrangeDesktopResults() : arrangeTabletMobileResults()}
      </div>
    );
  }

  const arrangeDesktopResults = () => {
    return (
      <>
        <div className="quick-answers-articles-column">
          {component.state.quickAnswersFlag &&
            <SearchQuickAnswers
              clickAnalytics={component.state.analyticsData && component.state.analyticsData.clickAnalytics}
              vehicle={component.state.currentVehicle}
              component = {component}
              imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
              highlightItems={component.state.highlightItems[0]}
              keyword={component.state.searchParam}
              numberOfSearchResults={component.state.articleResults.length.toString()}
            />}
          <SearchBodyResultContent
            component = {component}
          />
        </div>
        <div className="knowledge-pane-non-vehicle-pane-column">
          {component.state.knowledgePaneFlag &&
            <KnowledgePane
              vehicle={component.state.currentVehicle}
              isProd={component.state.currentVehicleType}
              details={component.state.currentVehicleDetails}
              clickAnalytics={component.state.analyticsData && component.state.analyticsData.clickAnalytics}
              keyword={component.state.searchParam}
              numberOfSearchResults={component.state.articleResults.length.toString()}
              imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
              component = {component}
            />}
          {component.state.nonVehicleSearchResults &&
            <NonVehicleSearchResultPane
              title={component.state.nonVehicleSearchResults.page.textToDisplay}
              pageData={component.state.nonVehicleSearchResults}
            />}
        </div>
      </>
    )
  }

  const arrangeTabletMobileResults = () => {
    return (
      <div className="quick-answers-knowledge-pane-articles-column">
          <div className="quick-answers-knowledge-pane-row">
            {component.state.quickAnswersFlag &&
              <SearchQuickAnswers
                clickAnalytics={component.state.analyticsData && component.state.analyticsData.clickAnalytics}
                vehicle={component.state.currentVehicle}
                component = {component}
                imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
                highlightItems={component.state.highlightItems[0]}
                keyword={component.state.searchParam}
                numberOfSearchResults={component.state.articleResults.length.toString()}
              />}
            {component.state.knowledgePaneFlag &&
              <KnowledgePane
                vehicle={component.state.currentVehicle}
                isProd={component.state.currentVehicleType}
                details={component.state.currentVehicleDetails}
                clickAnalytics={component.state.analyticsData && component.state.analyticsData.clickAnalytics}
                keyword={component.state.searchParam}
                numberOfSearchResults={component.state.articleResults.length.toString()}
                imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
                component = {component}
              />}
            {component.state.nonVehicleSearchResults &&
              <NonVehicleSearchResultPane
                title={component.state.nonVehicleSearchResults.page.textToDisplay}
                pageData={component.state.nonVehicleSearchResults}
                imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
              />}
          </div>
          <SearchBodyResultContent
            component = {component}
          />
      </div>
    );
  }

  return (
    component.state.searchParam !== '' && searchResults()
  );
}
