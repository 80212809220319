import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import SectionHeader from '../../components/atoms/SectionHeader';
import { postViewPageAction } from '../../services/nitroService';
import '../../styles/css/smartpath-training.css';
import history from '../app/history';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import { FilterMenu } from '../filterMenu/filterMenu.component';
import applyMarkdown from '../utils/applyMarkdown';
import VideoPlayerComponent from '../videoPlayer/videoPlayer.component';
import { downloadFullManualClick, filterLinksClick, pageLoadAnalytics, videoAnalytics } from './smartpath-analytics';

const formatFilterID = (filter) => {
  const id = filter.replace(/ /g, '_');
  return id;
};

const PDFContent = (content, imagePath, disclaimer) => (
  content.map((tile, index) => (
    <div className="smartpath-training-tile" key={index}>
      <img className="smartpath-training-pdf-image" src={imagePath + tile.image} />
      <div className="smartpath-training-tile-title">{applyMarkdown(tile.title, disclaimer)}</div>
      <div className="smartpath-training-pdf-description">{applyMarkdown(tile.description, disclaimer)}</div>
      <div className="smartpath-training-pdf-download"><a target="_blank" href={imagePath + tile.fileName} onClick={() => downloadFullManualClick(tile.fileName, tile.type)}>DOWNLOAD PDF</a></div>
    </div>
  ))
);

const PDFSection = (title, content, imagePath, disclaimer) => (
  <div className="smartpath-training-section grey-break-line">
    <div className="smartpath-training-section-title">{applyMarkdown(title, disclaimer)}</div>
    <div className="smartpath-training-pdf-content">{PDFContent(content, imagePath, disclaimer)}</div>
  </div>
);

const videoContent = (content, disclaimer) => (
  content.map((tile, index) => (
    <div className="smartpath-training-tile" key={index}>
      <VideoPlayerComponent
        key={tile.qumuId}
        item={{
          qumuId: tile.qumuId,
          alias: tile.brightcoveId,
          videoTitle: tile.title,
          hideCTA: false,
        }}
        sendVideoEventAnalytics={videoAnalytics.sendVideoEventAnalytics}
      />
      <div className="smartpath-training-tile-title">{applyMarkdown(tile.title, disclaimer)}</div>
      <div className="smartpath-training-video-description">{applyMarkdown(tile.description, disclaimer)}</div>
    </div>
  ))
);

const videoSection = (title, content, description, disclaimer) => (
  <div className="smartpath-training-section">
    <div className="smartpath-training-section-title">{applyMarkdown(title, disclaimer)}</div>
    {description ? <div className="smartpath-training-video-section-description">{applyMarkdown(description, disclaimer)}</div> : null}
    <div className="smartpath-training-video-content">{videoContent(content, disclaimer)}</div>
  </div>
);

const historyContent = (content, imagePath, disclaimer) => (
  content.map((tile, index) => (
    <div className="smartpath-training-history-tile columns end large-2 medium-3 small-4" key={index}>
      <a target="_blank" href={imagePath + tile.fileName} onClick={() => downloadFullManualClick(tile.fileName, tile.type)}>
        <div className="smartpath-training-history-title">{applyMarkdown(tile.title, disclaimer)}</div>
        <div className="smartpath-training-history-description">{applyMarkdown(tile.publicationDate, disclaimer)}</div>
      </a>
    </div>
  ))
);

const historySection = (title, content, imagePath, disclaimer) => (
  <div className="smartpath-training-history-section">
    <div className="smartpath-training-section-title">{applyMarkdown(title, disclaimer)}</div>
    <div className="smartpath-training-history-content row">{historyContent(content, imagePath, disclaimer)}</div>
  </div>
);

const trainingSections = (filters, imagePath, curFilter, disclaimer) => (
  filters.filter(x => formatFilterID(x.filterName) === curFilter).map((filter, index) => (
    <div key={index}>
      {filter.PDFSectionTitle ? PDFSection(filter.PDFSectionTitle, filter.PDFSectionContent, imagePath, disclaimer) : null}
      {filter.videoSectionTitle ? videoSection(filter.videoSectionTitle, filter.videoSectionContent, filter.videoSectionDescription, disclaimer) : null}
      {filter.historySectionTitle ? historySection(filter.historySectionTitle, filter.historySectionContent, imagePath, disclaimer) : null}
    </div>
  ))
);

export class SmartPathTraining extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(location.search);
    const setActiveFilter = query.tab ? query.tab : formatFilterID(props.trainingData.filters[0].filterName);

    this.state = {
      activeFilter: setActiveFilter,
    };
    this.switchFilter = this.switchFilter.bind(this);
  }

  componentWillMount() {
    this.formatFilters(this.props.trainingData.filters);
  }

  componentDidMount() {
    postViewPageAction(window.location.href);
    pageLoadAnalytics('Training');
    this.setState({
      disclaimer: this.props.disclaimer,
    });
  }

  switchFilter(event, filter) {
    const selectedFilter = filter.id;
    filterLinksClick(event, selectedFilter);
    this.setState({
      activeFilter: selectedFilter,
    });
    history.replace(`/smartPath/training?tab=${filter.url}`);
    postViewPageAction(window.location.href);
  }

  formatFilters(filters) {
    const availableFilters = [];
    for (const i in filters) {
      const filter = {
        id: formatFilterID(filters[i].filterName),
        label: filters[i].filterName,
        url: formatFilterID(filters[i].filterName),
      };
      availableFilters.push(filter);
    }

    const formattedFilters = [];
    const populatedFilters = [];
    for (const i in filters) {
      formattedFilters[filters[i].filterName.trim().replace(/ /g, '_')] = filters[i];
    }

    for (const filter in availableFilters) {
      for (const version in formattedFilters) {
        if (version === availableFilters[filter].id) {
          populatedFilters.push(availableFilters[filter]);
        }
      }
    }

    this.setState({
      filters: formattedFilters,
      filtersToDisplay: populatedFilters,
    });
  }


  render() {
    return (
      <div>
        <DisclaimersComponent template={this.state.disclaimer} ref={(instance) => { this.disclaimerModal = instance; }} />
        <div className="smartpath-training">
          <SectionHeader>{this.props.trainingData.header}</SectionHeader>
          <div className="smartpath-training-description">{applyMarkdown(this.props.trainingData.description)}</div>
          <FilterMenu
            active={this.state.activeFilter}
            filters={this.state.filtersToDisplay ? this.state.filtersToDisplay : []}
            filterClick={this.switchFilter}
            displayArrow={true}
          />
          <div>{this.disclaimerModal && trainingSections(this.props.trainingData.filters, this.props.imagePath, this.state.activeFilter, this.disclaimerModal)}</div>
        </div>
      </div>
    );
  }
}

SmartPathTraining.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  filters: PropTypes.array,
  trainingData: PropTypes.object,
  imagePath: PropTypes.string,
  downloadFullManualClick: PropTypes.func,
  disclaimer: PropTypes.string,
};
