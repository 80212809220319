import React, { useState, useEffect, useRef } from "react";
import "../../../../styles/scss/smartpathV2/resources/trainingContentCarousel.scss"
import DropdownV2 from "../../../../components/molecules/DropdownV2.js";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics.js";
import TrainingContentTile from "./TrainingContentTile";
import { isDesktopView, isTabletView } from "../../../utils/getScreenSize.js";

const TrainingContentCarousel  = ({ title, data }) => {
  const containerRef = useRef(null);
  const [filteredTiles, setFilteredTiles] = useState(data);
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState('All');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [numDots, setNumDots] = useState(0);
  const [dragState, setDragState] = useState({ isDragging: false, startX: 0, scrollLeft: 0 });

  const PADDING_AMMOUNT = isDesktopView(window.innerWidth) ? 240 : isTabletView(window.innerWidth) ? 60 : 24;
  const CARD_WIDTH = isDesktopView(window.innerWidth) ? 400 : 320;
  const GAP = isDesktopView(window.innerWidth) ? 32 : 24;

  useEffect(() => {
    const dropdownSet = new Set(["All"]);
    data.forEach(item => {
      if (item.pdfName) dropdownSet.add("PDFs");
      if (item.videoModalId) dropdownSet.add("Videos");
      if (item.startCourseLink) dropdownSet.add("Courses");
    });
    const uniqueDropdownOptions = Array.from(dropdownSet);
    setDropDownOptions(uniqueDropdownOptions);
    setSelectedDropdownOption(uniqueDropdownOptions[0]);
  }, []);

  useEffect(() => {
    if (selectedDropdownOption === "All") {
      setFilteredTiles(data);
    } else {
      const filteredData = data.filter((item) => {
        if (selectedDropdownOption === "PDFs") return item.pdfName;
        if (selectedDropdownOption === "Videos") return item.videoModalId;
        if (selectedDropdownOption === "Courses") return item.startCourseLink;
        return true;
      });
      setFilteredTiles(filteredData);
    }
  }, [selectedDropdownOption])

  useEffect(() => {
    setNumDots(getNumDots());
  }, [window.innerWidth, filteredTiles]);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      const temp = Math.round(container.scrollLeft / (CARD_WIDTH + GAP));
      const maxScroll = container.scrollLeft >= (container.scrollWidth - container.clientWidth);

      maxScroll ? setSelectedIndex(numDots - 1) : setSelectedIndex(temp);
    };
    
    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
  }, [CARD_WIDTH, GAP, numDots]);
  
  const handleMouseDown = (e) => {
    const { pageX } = e, container = containerRef.current;
    if (container) setDragState({ isDragging: true, startX: pageX - container.offsetLeft, scrollLeft: container.scrollLeft });
  };
  
  const handleMouseUpOrLeave = () => setDragState((prev) => ({ ...prev, isDragging: false }));
  
  const handleMouseMove = (e) => {
    if (!dragState.isDragging) return;
    const { pageX } = e, container = containerRef.current;
    if (container) {
      e.preventDefault();
      container.scrollLeft = dragState.scrollLeft - (pageX - container.offsetLeft - dragState.startX);
    }
  };

  const handleDropdownAnalytics = (option) => {
    clickAnalytics({
      category: 'Resources-Training Content',
      label: 'Dropdown Click',
      container: title,
      contentTitle: option
    });
  };

  function handleOptionChange(event) {
    setSelectedDropdownOption(event.value);
    handleDropdownAnalytics(event.value);
  };

  const getNumDots = () => {
    const temp = Math.floor((window.innerWidth - (PADDING_AMMOUNT * 2)) / CARD_WIDTH);
    return filteredTiles.length - temp + 1;
  };

  const Dots = ({selectedIndex}) => {
    const dots = Array.from({length: numDots});

    const getScrollPosition = (index) => {
      return index * (CARD_WIDTH + GAP);
    };

    if (numDots > 1) {
      return (
        <div className="dots-indicator">
          {dots.map((dot, index) => 
            <div className={`dot ${selectedIndex === index && 'selected'}`}  onClick={() => containerRef.current.scrollTo({left: getScrollPosition(index), behavior: 'smooth'})}/>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
      <div className="training-content-carousel-container">
          <div className="training-content-carousel-header-container">
              {title}
              <div className="training-content-dropdown">
              <DropdownV2
                  style={"black"}
                  options={dropdownOptions}
                  placeholder={null}
                  value={selectedDropdownOption?.toString()}
                  onValueChange={handleOptionChange}
              />
              </div>
          </div>
          <div className="training-content-carousel" 
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUpOrLeave}
            onMouseLeave={handleMouseUpOrLeave}
            onMouseMove={handleMouseMove}
          >
            {filteredTiles?.map(item => 
              <TrainingContentTile data={item} carouselTitle={title} />
            )}
          </div>
          <Dots
            selectedIndex={selectedIndex}
          />
      </div>
  );
}

export default TrainingContentCarousel;
