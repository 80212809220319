import React from "react";
import SmartPathEmailForm from "./components/smartPathEmailForm.js";
import StickyTabs from "../sharedComponents/stickyTabs.js";
import { generateTabContentContainer, shouldExtendToEdgeOfScreen } from "../sharedComponents/utils.js";

const SmartPathGetStarted = ({getStartedData, sharedData}) => {

  return (
    <div className="smartpath-get-started-page">
      {getStartedData.getStartedTabs.map(tabGroup => 
        <StickyTabs
          title={tabGroup.componentTitle}
          tabs={generateTabContentContainer(tabGroup.tabs, sharedData.children, tabGroup.componentTitle)}
          extendToScreenEdge={shouldExtendToEdgeOfScreen(tabGroup.tabs, sharedData.children)}
        />
      )}
      
      <SmartPathEmailForm
        services={getStartedData.emailFormServices}
        formLabels={getStartedData.emailForm}
      />
    </div>
  );
};

export default SmartPathGetStarted;
