import VideoSpotlight from "../whatIsSmartPath/components/videoSpotlight";
import SalesToolCarousel from "../whatIsSmartPath/components/salesToolCarousel";
import LogoShowcase from "../whatIsSmartPath/components/logoShowcase";
import PricingSection from "../getStarted/components/pricingSection";
import PreflightChecklist from "../getStarted/components/preFlightChecklist";
import AppSettings from "../../../appsettings";

export const getChildrenContainer = (childrenData, childrenIds, analyticsData) => {

    const getChild = (childId) => {
        const child = childrenData.find(item => item.uniqueId === childId);
        switch (child.type) {
            case "video-spotlight": 
                return <VideoSpotlight data={child.data} analyticsData={analyticsData} key={`${analyticsData.title}-${child.data.title}`}/>;
            case "sales-tool-carousel": 
                return <SalesToolCarousel data={child.data} analyticsData={analyticsData} key={`${analyticsData.title}-${child.data.componentTitle}`}/>;
            case "logo-showcase": 
                return <LogoShowcase data={child.data} key={`${analyticsData.title}-${child.data.componentTitle}`}/>;
            case "pricing-section": 
                return <PricingSection data={child.data} key={`${analyticsData.title}-${child.data.componentTitle}`}/>;
            case "checklist": 
                return <PreflightChecklist data={child.data} key={`${analyticsData.title}-${child.data.componentTitle}`}/>;
            default:
                return null;
        }
    };

    return (
        <>
            {childrenIds.map(childId => getChild(childId))}
        </>
    );
};

export const generateTabContentContainer = (tabs, childrenData, sectionName) => {
    return tabs.map(tab => {
        return (
            {
                title: tab.tabName,
                component: getChildrenContainer(childrenData, tab.childrenIds, {title: tab.tabName, container: sectionName })
            }
        )
    });
};

export const shouldExtendToEdgeOfScreen = (tabs, childrenData) => {
    const allIds = tabs.map(tab => tab.childrenIds).flat();
    const types = allIds.map((id) => {
        const match = childrenData.find(child => child.uniqueId === id);
        return match.type;
    });
    return types.includes('pricing-section');
};

export const openPdf = (pdfName) => {
    const url = `${AppSettings.AWSImgRepo.smartPathV2}${pdfName}`;
    window.open(url, '_blank');
};

export const openLink = (link) => {

    if (link.charAt(0) === '/' || link.includes('engage.toyota.com')) {
        // relative path or internal link
        window.location.href = link;
    }  else {
        // external link
        window.open(link, '_blank');
    }
};