import React from "react";
import MarketingAssestsTile from "./MarketingAssestsTile";
import StickyTabs from "../../sharedComponents/stickyTabs";
import ResourcesLinkout from "./resourcesLinkout";
import { isMobileView } from '../../../../screens/utils/getScreenSize';
import "../../../../styles/scss/smartpathV2/resources/marketingAssets.scss"

const MarketingAssets = ( {marketingAssestsData, miscData} ) => {

  // Extract unique categories
  const uniqueCategories = [...new Set(marketingAssestsData.map(item => item.category))];

  const MarketingAssestsTileContainer = ({tileData, analyticsData}) => {
    return (
      <div className="marketing-assets-tile-container">
        {tileData.map(item =>
          <MarketingAssestsTile
            title={item.title}
            label={item.label}
            analyticsData={analyticsData}
            thumbnail={item.thumbnail}
            file={item.file}
          />
        )}
      </div>
    );
  };

  const tabs = uniqueCategories.map(category => {
    return (
      {
        title: category,
        component: (
          <>
            <MarketingAssestsTileContainer tileData={marketingAssestsData.filter(tile => tile.category === category)} analyticsData={{ title: category }} />
            {!isMobileView(window.innerWidth)? <ResourcesLinkout data={miscData} /> : null }
          </>
        )
      }
    );
  });
 
  return (
    <div className="smartpath-marketing-assets-container">
      <StickyTabs
        tabs={tabs}
        container="Marketing Assets"
      />
      {isMobileView(window.innerWidth)? <ResourcesLinkout data={miscData} /> : null}
    </div>
  );
};

export default MarketingAssets;
