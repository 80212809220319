import React, { useState, useRef, useEffect } from 'react';
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/salesToolCarousel.scss";
import Slider from "react-slick";
import appsettings from "../../../../appsettings";
import SalesToolTile from './salesToolsTile';
import { clickAnalytics } from '../../sharedComponents/smartpath.analytics';

const SalesToolCarousel = ({ data, analyticsData }) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [numOfSlides, setNumOfSlides] = useState(3);

    const handleCarouselClickAnalytics = (arrowDirection) => {
        clickAnalytics({
          category: 'What is SmartPath',
          label: 'Carousel ' + arrowDirection,
          contentTitle: analyticsData?.title,
          container: analyticsData?.container
        });
      };

    const calculateNumOfSlides = () => {
        if (window.innerWidth < 970) {
            return 1; 
        } else if (window.innerWidth < 1350) {
            return 2;
        } else {
            return 3;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setNumOfSlides(calculateNumOfSlides());
        };
        
        setNumOfSlides(calculateNumOfSlides());
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        adaptiveHeight: true,
        swipe: true,
        slidesToShow: numOfSlides,
        slidesToScroll: 1,
        variableWidth: true,
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
            handleCarouselClickAnalytics('Prev');
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
            handleCarouselClickAnalytics('Next');
        }
    };

    const handleAfterChange = (index) => {
        setCurrentSlide(index);
    };

    const isNextDisabled = () => {
        // Check if we're at the final slide and consider the case when there are fewer than set number of slides
        return currentSlide + numOfSlides >= data.cards.length || data.cards.length <= numOfSlides;
    };

    return (
        <div className="sales-tool-carousel-container">
            <div className="sales-tool-carousel-header-container">
                <div className="sales-tool-carousel-title">{data.componentTitle}</div>
                <div className={`sales-tool-carousel-buttons ${data.cards.length <= numOfSlides ? 'disabled' : ''}`}>
                    <button className={`sales-tool-prev-button ${currentSlide === 0 ? 'disabled' : ''}`} onClick={handlePrev} disabled={currentSlide === 0}>
                        <img src={appsettings.AWSImgRepo.resources + 'en/icon-chevron-left.svg'} alt="Prev"/>
                    </button>
                    <button className={`sales-tool-next-button ${isNextDisabled() ? 'disabled' : ''}`} onClick={handleNext} disabled={isNextDisabled()}>
                        <img src={appsettings.AWSImgRepo.resources + 'en/icon-chevron-left.svg'} alt="Next"/>
                    </button>
                </div>
            </div>
            <div className="sales-tool-carousel">
                <Slider {...settings} ref={sliderRef} afterChange={handleAfterChange} >
                    {data.cards?.map((item, index) => (
                        <div key={index}>
                            <SalesToolTile
                                title={item.cardTitle}
                                description={item.cardDescription}
                                backgroundImage={item.cardImage}
                                label={item.cardLabel}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default SalesToolCarousel;