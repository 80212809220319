import React from 'react';
import AppSettings from "../../../../appsettings.js";
import { isDesktopView, isTabletView } from '../../../utils/getScreenSize.js';
import { useHistory } from 'react-router';
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/readyToSignUp.scss"
import { clickAnalytics } from '../../sharedComponents/smartpath.analytics.js';

const ReadyToSignUp = ({data}) => {
  const history = useHistory();

  const handleButtonClickAnalytics = () => {
    clickAnalytics({
      category: 'What is SmartPath',
      label: 'Get Started Button',
    });
  };

  const handleButtonClick = () => {
    handleButtonClickAnalytics();
    history.push('/smartPath/get-started');
    window.scrollTo(0, 0);
  };

  const TileComponent = ({ desktopImage , tabletImage , mobileImage, label, title }) => {
    const imageSrc = isDesktopView(window.innerWidth)? desktopImage : isTabletView(window.innerWidth)? tabletImage : mobileImage;

    return (
      <div className="rts-step-container">
        <img src={`${AppSettings.AWSImgRepo.smartPathV2}${imageSrc}`} alt="Tile" className="rts-tile-image" draggable="false"/>
        <div className="rts-content">
          <div className="rts-content-label">{label}</div>
          <div className="rts-content-text">{title}</div>
        </div>
      </div>
    );
  };

  return (
    <div className='rts-container'>
      <div className='rts-component'>
        <h2 className="rts-header">{data.componentTitle}</h2>
        <div className="rts-tile-container">
          {data.tiles.map((tile, index) => (
            <TileComponent 
              key={index} 
              desktopImage={tile.desktopImage} 
              tabletImage={tile.tabletImage}
              mobileImage={tile.mobileImage}
              label={tile.label} 
              title={tile.title} 
            />
          ))}
        </div>
        <button className="rts-button" onClick={() => handleButtonClick()}>
          {data.buttonText}
        </button>
      </div>
    </div>
  );
};

export default ReadyToSignUp;
