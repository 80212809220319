import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as DownloadIcon } from "../../../resources/images/download-cloud-icon.svg";

export const addDownloadButton = (player, downloadLink) => {
    // Create the container element for the button
    const buttonContainer = document.createElement('div');
    buttonContainer.style = 'display: contents';

    // Use ReactDOM to render the SVG inside the button
    ReactDOM.render(
        <button className='vjs-control vjs-button' style={{ cursor: "pointer" }}
            onClick={() => {
                const link = document.createElement('a');
                link.href = downloadLink;
                link.download = '';
                link.click();
            }}
        >
            <span className='vjs-svg-icon'>
                <DownloadIcon height="22" width="22" color="#FFF" />
            </span>
        </button>,
        buttonContainer
    );

    // Add the button to the control bar at the specified index
    const controlBar = player.getChild('controlBar').el();
    const children = Array.from(controlBar.children); // Get current children as an array
    const targetIndex = 12; // Index controls position in the control bar

    if (targetIndex >= 0 && targetIndex < children.length) {
        // Insert the button container at the target index
        controlBar.insertBefore(buttonContainer, children[targetIndex]);
    } else {
        // Append to the end if the index is out of bounds
        controlBar.appendChild(buttonContainer);
    }
};