import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

export const PageLoadAnalytics = (pageLoad) => {
    pageLoad &&
        analytics.firePageLoad({
            section: 'Product',
            subsection: 'Featured Technology',
            technologyName: 'SmartPath',
            page: pageLoad.page,
        });
};

export const clickAnalytics = (clickItem) => {
    clickItem &&
        analytics.fireOnClick({
            tag: '29.2',
            section: 'Product',
            page: 'SmartPath',
            category: clickItem.category,
            label: clickItem.label,
            container: clickItem.container,
            contentTitle: clickItem.contentTitle
        });
};