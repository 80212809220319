import React, { useState } from "react";
import S from "../../../../styles/scss/smartpathV2/resources/releaseNotesTiles.module.scss";
import ReleaseNotesTile from "./releaseNotesTile";
import ReleasePagination from "./releasePagination";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";

const ReleaseNotesTiles = ({ items, showViewMoreButton, scrollIntoView, sectionTitle }) => {
  const itemsPerPage = 9;
  const initialVisible = showViewMoreButton ? 3 : Math.min(9, items.length);
  const [visibleRange, setVisibleRange] = useState([0, initialVisible]);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const currentPage = Math.floor(visibleRange[0] / itemsPerPage) + 1;
  const visibleItems = items.slice(visibleRange[0], visibleRange[1]);
  const [viewMoreClicked, setViewMoreClicked] = useState(false);

  const handleClickAnalytics = (label) => {
    clickAnalytics({
      category: 'Resources-Release Notes',
      label: label
    });
  };

  const handleViewMore = () => {
    setVisibleRange([0, Math.min(9, items.length)]);
    setViewMoreClicked(true);
    handleClickAnalytics('View More');
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newStart = visibleRange[0] + itemsPerPage;
      const newEnd = Math.min(newStart + itemsPerPage, items.length);
      setVisibleRange([newStart, newEnd]);
      scrollIntoView && scrollIntoView();
      handleClickAnalytics('Next');
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newStart = Math.max(0, visibleRange[0] - itemsPerPage);
      const newEnd = newStart + itemsPerPage;
      setVisibleRange([newStart, newEnd]);
      scrollIntoView && scrollIntoView();
      handleClickAnalytics('Prev');
    }
  };

  return (
    <>
      <div className={S["release-notes-section"]}>
        {visibleItems.map((item) => (
          <React.Fragment key={item.id}>
            <ReleaseNotesTile
              image={item.image}
              date={item.date}
              category={item.category}
              description={item.description}
              pdfFile={item.pdfFile}
              sectionTitle={sectionTitle}
            />
          </React.Fragment>
        ))}
      </div>
      {showViewMoreButton &&
        !viewMoreClicked &&
        visibleRange[1] < 9 &&
        items.length > initialVisible && (
          <div className={S["button-container"]}>
            <button
              className={S["rounded-button"]}
              onClick={() => handleViewMore()}
            >
              View More
            </button>
          </div>
        )}

      {(!showViewMoreButton || viewMoreClicked) && visibleRange[1] >= 9 && totalPages > 1 && (
        <ReleasePagination
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
    </>
  );
};

export default ReleaseNotesTiles;
