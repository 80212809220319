import React from "react";
import AppSettings from "../../../../appsettings";
import { ReactComponent as DownloadIcon } from "../../../../resources/images/download_icon.svg";
import "../../../../styles/scss/smartpathV2/resources/marketingAssetsTile.scss";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
const MarketingAssetsTile = ({ title, label, thumbnail, file, analyticsData }) => {


  const handleDownloadAnalytics = () => {
    clickAnalytics({
      category: 'Resources-Marketing Assets',
      label: 'Download Click',
      container: analyticsData?.title,
      contentTitle: title
    });
  };

  const handleClick = () => {
    downloadImage();
    handleDownloadAnalytics();
  }

  async function downloadImage() {
    const image = await fetch(`${AppSettings.AWSImgRepo.smartPathV2}${file}`);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
  
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="marketing-assets-tile">
        <div className="image-container">
            <img
                className="marketing-asset-thumbnail"
                src={`${AppSettings.AWSImgRepo.smartPathV2}${thumbnail}`}
                alt="marketing asset"
                draggable="false"
            />
            <DownloadIcon className="download-icon" onClick={() => handleClick()}/>
        </div>

        <div className="asset-title">{title}</div>
        <div className="asset-label">{label}</div>
    </div>
  );
};

export default MarketingAssetsTile;
