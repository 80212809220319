import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/smartpath-overview-support.css';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';
import * as supportAnalytics from '../overviewAnalytics/smartpath-overview-support.analytics';
import useOnScreen from '../useIntersection';
import ReactFreezeframe from 'react-freezeframe';

export const SmartPathOverviewSupport = (props) => {
    const supportRef = useRef();
    if (useOnScreen(supportRef) && props.pageLoadAnalytics) {
        supportAnalytics.supportSubLoad(props.pageLoadAnalytics);
    }

    const supportAnalyticsLinkClick = (linkText) => {
        if (props.clickAnalytics && linkText) {
            supportAnalytics.linkClick(props.clickAnalytics, linkText);
        }
    };
    
    const supportHeader = (
        <div className = "smartpath-support-header" > 
            <div className = "smartpath-support-header-title">
                {applyMarkdown(props.support.header)}
            </div>
            <div className = "smartpath-support-header-description">{applyMarkdown(props.support.description)}</div>
            <div className = "smartpath-support-header-content">
                <div className = "smartpath-support-header-content-left">
                    <div className = "smartpath-support-header-content-icon"><img src={props.imagePath + props.support.leftImage}/></div>
                    <div className = "smartpath-support-header-content-description">{applyMarkdown(props.support.leftDescription)}</div>
                </div>
                <div className ="smartpath-support-divider"></div>
                <div className = "smartpath-support-header-content-right">
                    <div className = "smartpath-support-header-content-icon"><img src={props.imagePath + props.support.rightImage}/></div>
                    <div className = "smartpath-support-header-content-description" onClick={() => supportAnalyticsLinkClick(props.support.rightDescription)}>{applyMarkdown(props.support.rightDescription)}</div>
                </div>
            </div>
        </div>
    )

    const isVideoImageOrGif = function(qumuId,contentImage,contentGif){
       
        if(qumuId){
            return <VideoPlayerComponent
            item={{
              qumuId: qumuId,
              videoTitle: 'support Overview Video',
              contentTitle: 'support',
              hideCTA: false,
              
            }}
        />
        }
        else if(contentImage){
            return <div><img src={props.imagePath + contentImage}/></div>
        }
        else if(contentGif){
            return <div><img src={props.imagePath + contentGif}/></div>
        }
        else return
    }
    const getLinks = function(Links){

        return Links.map(link =>(
            <div className = "smartpath-support-links">
                <a href ={link.url} onClick={() => supportAnalyticsLinkClick(link.linkText)}>{link.linkText}</a>
            </div>
        ))
    };
    
    const supportBigCards = props.support.content.slice(0,2).map(content => (
    
        <div className = "smartpath-support-bigCard">
            <div>{isVideoImageOrGif(content.qumuId,content.image,content.gif)}</div>
            <div className = "smartpath-support-bigCard-title">{content.title}</div>
            <div className = "smartpath-support-bigCard-description">
                {applyMarkdown(content.description)}
                {getLinks(content.links)}
                </div>
            
        </div>
    ));

    const supportCards = props.support.content.slice(2).map(content =>(
        <div className = "smartpath-support-cards">
            <div className ="smartpath-support-cardIcon" >{<img src={props.imagePath + content.image}/>}</div>
            <div className = "smartpath-support-cardTitle">{applyMarkdown(content.title)}</div>
            <div className = "smartpath-support-cardContent">
                {applyMarkdown(content.description)}
                {getLinks(content.links)}
            </div>

        </div>
    ));
     

    return <div className = "smartpath-support" ref={supportRef}>
        <div>
          {supportHeader}
        </div>

        <div className = "smartpath-support-bigCards">
            {supportBigCards}
        </div>
        <div className = "smartpath-support-content">{supportCards}</div>
      
    </div>
  
};
