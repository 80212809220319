import React, { useEffect, useState, useRef, useContext } from "react";
import "../../../styles/scss/smartpathV2/sharedComponents/videoModal.scss";
import Modal from 'react-responsive-modal';
import VideoPlayerComponent from '../../../screens/videoPlayer/videoPlayer.component';
import { isMobileView, isTabletView } from "../../utils/getScreenSize";
import { getQumuSources } from '../../../services/videoLookupService';
import { ReactComponent as CloseIcon } from '../../../resources/images/close-icon.svg';
import { SmartPathContext } from "./smartPathContext";
import { clickAnalytics } from "./smartpath.analytics";

const VideoModal = ({ data, open, onCloseModal }) => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const isMobileOrTablet = () => isTabletView(window.innerWidth) || isMobileView(window.innerWidth);
  const [videosData, setVideosData] = useState(null);
  const smartPathContext = useContext(SmartPathContext);
  
  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.body;

    if (open) {
      html.classList.add("overlay-disable-body-scroll");
      body.classList.add("overlay-disable-body-scroll");
    } else {
      html.classList.remove("overlay-disable-body-scroll");
      body.classList.remove("overlay-disable-body-scroll");
    }

  }, [open]);

  const formatTime = (milliseconds) => {
    const date = new Date(milliseconds);
    const isoString = date.toISOString();
    const timePart = isoString.slice(11, 19);  // hh:mm:ss part
    return timePart.startsWith('00') ? timePart.slice(3) : timePart;
  };

  const handleButtonClickAnalytics = () => {
    clickAnalytics({
      category: smartPathContext?.currentTabId?.replace(/[^\w\s]/g, ''),
      label: 'Video Modal Close',
      contentTitle: data?.title
    });
  }

  const handleVideoClickAnalytics = (videoId) => {
    clickAnalytics({
      category: 'Video Modal',
      label: 'Select Video',
      contentTitle: videoId
    });
  }

  const handleClose = () => {
    onCloseModal();
    handleButtonClickAnalytics(); 
  };

  const handleMouseDown = (event) => {
    const container = containerRef.current;
    if (container) {
      setIsDragging(true);
      setStartX(event.pageX - container.offsetLeft);
      setScrollLeft(container.scrollLeft);
    }
  };

  const handleMouseUpOrLeave = () => { setIsDragging(false); };

  const handleMouseMove = (event) => {
    const container = containerRef.current;
    if (!isDragging) return;
    event.preventDefault();
    const xCurrentPosition = event.pageX - container.offsetLeft;
    const walk = (xCurrentPosition - startX);
    container.scrollLeft = scrollLeft - walk;
  };

  const handleVideoClick = (videoId) => {
    const selectedVideo = data?.qumuIDs?.find((id) => id === videoId);
    setCurrentVideo(selectedVideo);
    handleVideoClickAnalytics(selectedVideo);
  };

  useEffect(() => {
    if (data?.qumuIDs?.length > 0 && !videosData) {
      setCurrentVideo(data?.qumuIDs[0]);
      async function fetchSources() {
        try {
          const fetchedData = await Promise.all(
            data?.qumuIDs.map(async (id) => {
              const data = await getQumuSources(id);
              return { data };
            })
          );
          setVideosData(fetchedData);
        } catch (error) {
          console.error("Error fetching sources", error);
        }
      }
      fetchSources();
    }

  }, [data]);

  const videoContainer = (
    <div className="video-container">
      <div className="modal-title">
        {data?.title}
      </div>
      <div className={`${data?.qumuIDs?.length > 1 ? 'video-section' : 'video-section noCollection'}`}>
        <div className="video-main-video-container">
          <div className="video-main-video">
            {currentVideo &&
              <VideoPlayerComponent
                key={currentVideo}
                item={{ qumuId: currentVideo?.toString() }}
              />
            }
          </div>
          {data?.qumuIDs?.length > 1 && <div className="video-description-container">
            <div className="video-title">
              {videosData?.find((videoObj) => videoObj?.data?.id === currentVideo)?.data?.title}
            </div>
            <div className="video-description">
              {videosData?.find((videoObj) => videoObj?.data?.id === currentVideo)?.data?.description}
            </div>
          </div>}
        </div>
        {data?.qumuIDs?.length > 1 && <div className="video-collection-section">
          <div className="video-collection-title">Collections</div>
          <div className="video-collection-list"
            ref={containerRef}
            onMouseDown={isMobileOrTablet() ? handleMouseDown : undefined}
            onMouseUp={isMobileOrTablet() ? handleMouseUpOrLeave : undefined}
            onMouseLeave={isMobileOrTablet() ? handleMouseUpOrLeave : undefined}
            onMouseMove={isMobileOrTablet() ? handleMouseMove : undefined}
          >
            {data?.qumuIDs?.map((id) => (
              <div key={id} className="video-item">
                <div className={`video-modal-thumbnail ${currentVideo === id ? 'has-border' : ''}`}>
                  <img
                    alt={videosData?.find((videoObj) => videoObj?.data?.id === id)?.data?.title || 'No Video Found'}
                    src={videosData?.find((videoObj) => videoObj?.data?.id === id)?.data?.poster}
                    onClick={() => handleVideoClick(id)}
                    style={currentVideo === id ? { borderRadius: '8px', filter: 'grayscale(30%) brightness(70%)', opacity: '75%' } : {}}
                  />
                  {currentVideo === id && <div className="video-modal-selected-text"> NOW PLAYING </div>}
                </div>
                <div className="video-modal-description">
                  <div className={`video-modal-title ${currentVideo === id ? 'bolded' : ''}`}>
                    {videosData && videosData.find((videoObj) => videoObj.data.id === id)?.data.title}
                  </div>
                  <div className="video-modal-video-length">
                    {videosData && formatTime(videosData.find((videoObj) => videoObj.data.id === id)?.data.duration)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>}
      </div>
    </div >
  );

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      closeIcon={<div className="video-modal-close-icon"><CloseIcon /></div>}
      classNames={{ modal: `${data?.qumuIDs?.length > 1 ? 'video-modal' : 'video-modal noCollection'}` }}>
      {videoContainer}
    </Modal>
  );
};

export default VideoModal;
