import React, { createContext, useContext, useState, useEffect } from 'react';

const VideoJSPlayerContext = createContext(null);

export const VideoJSPlayerProvider = ({ children }) => {
  const [player, setPlayer] = useState(null);
  const [playerStatus, setPlayerStatus] = useState('not-started');
  // possible values: 'not-started' | 'playing' | 'paused' | 'ended'

  useEffect(() => {
    if (!player) {
      // If there's no player, revert to "not-started"
      setPlayerStatus('not-started');
      return;
    }

    // Default to 'not-started' when a new player is created
    setPlayerStatus('not-started');

    const handlePlay = () => {
      setPlayerStatus('playing');
    };

    const handlePause = () => {
      // If the video has started before, it's now paused
      // Otherwise it stays 'not-started'
      if (player.hasStarted()) {
        setPlayerStatus('paused');
      } else {
        setPlayerStatus('not-started');
      }
    };

    const handleEnded = () => {
      setPlayerStatus('ended');
    };

    player.on('play', handlePlay);
    player.on('pause', handlePause);
    player.on('ended', handleEnded);

    return () => {
      player.off('play', handlePlay);
      player.off('pause', handlePause);
      player.off('ended', handleEnded);
    };
  }, [player]);

  return (
    <VideoJSPlayerContext.Provider
      value={{
        player,
        setPlayer,
        playerStatus,
      }}
    >
      {children}
    </VideoJSPlayerContext.Provider>
  );
};

export const useVideoJSPlayerContext = () => useContext(VideoJSPlayerContext);