import React from 'react';
import ReactFreezeframe from 'react-freezeframe';
import PropTypes from 'prop-types';

import applyMarkdown from '../../../screens/utils/applyMarkdown';
import VideoPlayerComponent from '../../../screens/videoPlayer/videoPlayer.component';
import '../../../styles/css/marketingPage/pageHeader.css'

const PageHeader = (props) => {
    const heroGif = (gif, image) => (
        <div className={`hero-gif ${props.device}`}>
            {gif ? <ReactFreezeframe src={props.imagePath + gif} /> : <img src={props.imagePath + image} />}
        </div>
    );

    const heroMedia = () => (
        props.heroVideoId ?
            heroVideo(props.analytics && props.analytics.video, props.heroVideoId) :
            heroGif(props.heroGif, props.image)
    );

    const heroVideo = (videoAnalytics, videoId) => (
        <div className={`hero-video ${props.device}`}>
            <VideoPlayerComponent
                analyticsModule={props.videoAnalytics && props.videoAnalytics.module}
                analyticsSection={props.videoAnalytics && props.videoAnalytics.section}
                analyticsTag={props.videoAnalytics && props.videoAnalytics.videoTagId}
                item={{
                    hideCTA: false,
                    pageTitle: props.videoAnalytics && props.videoAnalytics.page,
                    qumuId: videoId,
                }}
            />
        </div>
    );

    const pageTitle = () => (
        <div className={`page-title ${props.device}`}>
            {applyMarkdown(props.title)}
        </div>
    );

    const scrollButtons = () => {
        let scrollButtons;

        if (props.device === 'mobile') {
            scrollButtons = (
                <>
                    <div className={`marketing-page-header-buttons ${props.device}`}>
                        {props.scrollButtons[0]}
                        {props.scrollButtons[1]}
                    </div>
                    <div className={`marketing-page-header-buttons line-two ${props.device}`}>
                        {props.scrollButtons[2]}
                        {props.scrollButtons[3]}
                    </div>
                </>
            );
        } else {
            scrollButtons = (
                <div className={`marketing-page-header-buttons ${props.device}`}>
                    {props.scrollButtons[0]}
                    {props.scrollButtons[1]}
                    {props.scrollButtons[2]}
                    {props.scrollButtons[3]}
                </div>
            );
        }
        
        return scrollButtons;
    };

    return (
        <div className={`marketing-page-header ${props.device}`} >
            {pageTitle()}
            {props.scrollButtons && scrollButtons()}
            {heroMedia()}
        </div>
    );
};

PageHeader.propTypes = {
    device: PropTypes.string,
    heroGif: PropTypes.string,
    heroVideoId: PropTypes.string,
    image: PropTypes.string,
    imagePath: PropTypes.string,
    title: PropTypes.string,
    scrollButtons: PropTypes.arrayOf(PropTypes.element),
    videoAnalytics: PropTypes.shape({
        section: PropTypes.string,
        page: PropTypes.string,
        module: PropTypes.string,
    }),
};

export default PageHeader;
