import React from "react";
import "../../../../styles/scss/smartpathV2/resources/smartpathServiceSupport.scss";
import AppSettings from '../../../../appsettings';
import applyMarkdown from "../../../utils/applyMarkdown";
import VideoPlayerComponent from "../../../videoPlayer/videoPlayer.component";
import { isDesktopView, isMobileView, isTabletView } from "../../../utils/getScreenSize";

const SmartpathSupportServices = ({ data }) => {
  return (
    <div className="smartpath-support-container">
      <div className="smartpath-support-content-holder">
        <div className="content-section">
          <div className="header-section">
            <div className="header">{data.headline}</div>
            <div className="header-subtitle">{data.description}</div>
            { (isMobileView(window.innerWidth) || isTabletView(window.innerWidth)) &&
              <div className="video-content" style={!(data?.qumuId || data?.mainImage) ? { display: 'none' } : {}} >
                {(data?.qumuId || data?.qumuId && data?.mainImage) && <VideoPlayerComponent item={{ qumuId: data?.qumuId.toString() }} />}
                {(data?.mainImage && (!data?.qumuId && data?.mainImage)) && <img src={`${AppSettings.AWSImgRepo.smartPathV2}${data?.mainImage}`} alt="support"/>}
              </div>
            }
          </div>

          <div className="support-services">
            {data.services?.map((service, index) => (
              <div className="service-container" key={index}>
                <div className="service-title">
                  <img src={`${AppSettings.AWSImgRepo.smartPathV2}${service.icon}`} alt={service.sectionName} />
                  <div >{service.sectionName}</div>
                </div>

                <div className="subtitle">{applyMarkdown(service.sectionDescription)}</div>
              </div>
            ))}

          </div>
        </div>
        { isDesktopView(window.innerWidth) &&
          <div className="video-content" style={!(data?.qumuId || data?.mainImage) ? { display: 'none' } : {}} >
            {(data?.qumuId || data?.qumuId && data?.mainImage) && <VideoPlayerComponent item={{ qumuId: data?.qumuId.toString() }} />}
            {(data?.mainImage && !data?.qumuId) && <img src={`${AppSettings.AWSImgRepo.smartPathV2}${data?.mainImage}`} alt="support"/>}
          </div>
        }
      </div>
    </div>
  );
};

export default SmartpathSupportServices;

