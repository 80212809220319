import { useEffect, useRef } from 'react';
import { useVideoJSPlayerContext } from './VideoJSPlayerContext.js';
import { registerAnalyticsPlugin } from './VideoJSAnalyticsPlugin.js';
import { addShareButton } from './VideoJSAddShareButton.js';
import { addDownloadButton } from './VideoJSAddDownloadButton.js';
import { merge } from 'lodash';
import '../../../styles/scss/videoJS/video-js-custom.scss';


const VideoJSComponent = ({ options = {}, trackData = {}, hideCTA = false, ...props }) => {
    const containerRef = useRef(null);
    const playerRef = useRef(null);
    const context = useVideoJSPlayerContext();

    const { shareLink, poster, hlsSrc, mobileSrc, canonicalSrc, title, id, duration } = trackData;
    const { analyticsModule, analyticsSection, analyticsPage, analyticsTag, analyticsVehicleSpecs } = props;
    const downloadLink = mobileSrc || canonicalSrc;

    // Default Video.js options
    const defaultOptions = {
        controls: true,
        autoplay: false,
        preload: 'auto',
        aspectRatio: '16:9',
        bigPlayButton: true, // disable when using overlay, overlay has its own button
        userActions: {
            click: true // toggle click-anywhere-to-play (only applies to the video, not the overlay)
        },
        controlBar: {
            pictureInPictureToggle: false, // Disables the Mini Player button
        },
        experimentalSvgIcons: true, // control bar uses svgs, this is to match our custom buttons
    };
    const mergedOptions = merge({}, defaultOptions, options);

    // Initialize Video.js
    useEffect(() => {
        // Check if the Video.js library is loaded from the CDN. Future Todo: move to npm package (currently blocked by node-sass)
        if (!window.videojs || !containerRef.current) return;

        // Register plugins
        registerAnalyticsPlugin(window.videojs);

        // Create <video-js> element and attach it
        const videoElement = document.createElement('video-js');
        containerRef.current.appendChild(videoElement);

        // Initialize player with options from props
        const player = playerRef.current = window.videojs(videoElement, mergedOptions);

        // Set Player Src
        player.poster(poster);
        player.src({ src: hlsSrc, type: 'application/x-mpegURL' });

        // Add Custom Buttons
        if (!hideCTA) {
            downloadLink && addDownloadButton(player, downloadLink);
            shareLink && addShareButton(window.videojs, player, shareLink);
        }
        
        // Activate Analytic Plugin, 
        player.AnalyticsPlugin({
            analyticsModule,
            analyticsSection,
            analyticsPage,
            analyticsTag,
            analyticsVehicleSpecs,
            videoDuration: duration,
            title,
            id
        });

        // Send play ref to player context
        if (context) context.setPlayer(player);

        // Cleanup on unmount
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
            }
            playerRef.current = null;
        };
    }, [options, trackData]);


    return (
        <div data-vjs-player style={{ position: "relative", height: "100%"}} >
            <div ref={containerRef} className="vjs-container" style={{ position: "relative", height: "100%"}} />
        </div>
    );
};

export default VideoJSComponent;