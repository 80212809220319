import { useRef } from "react";
import appsettings from "../../../../appsettings";
import S from "../../../../styles/scss/smartpathV2/resources/searchResults.module.scss";
import ReleaseNotesTiles from "./releaseNotesTiles";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";

const backButtonSvg = appsettings.AWSImgRepo.resources + "en/back-button-red-arrow.svg";

const SearchResults = ({ results, searchText, clear }) => {
  const containerRef = useRef(null);

  const scrollIntoView = () => {
    const yOffset = -150;
    const y = containerRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className={S["search-results-container"]} ref={containerRef} key={results}>
      <SearchResultsHeader
        searchText={searchText}
        count={results.length}
        handleBackButton={clear}
      />
      {results.length ? <ReleaseNotesTiles sectionTitle={"Search"} items={results} scrollIntoView={scrollIntoView} /> : <NoResults />}
    </div>
  );
};

const SearchResultsHeader = ({ searchText, count, handleBackButton }) => {
  
  const handleClickAnalytics = () => {
    clickAnalytics({
      category: 'Resources-Release Notes',
      label: 'Back'
    });
  };

  const handleBack = () => {
    handleBackButton();
    handleClickAnalytics();
  };
  
  return (
    <div className={S["header"]}>
      <button onClick={handleBack}>
        <img src={backButtonSvg} alt="back-icon" />
        <span>Back</span>
      </button>
      <div>
        <div className={S["results-for"]}>
          <span>Results for </span>
          <span>"{searchText}"</span>
        </div>
        <div className={S["results-count"]}>
          <span>{count}</span>
          <span> result{count !== 1 ? "s": ""} found</span>
        </div>
      </div>
    </div>
  );
};

const NoResults = () => {
  return (
    <div className={S["no-results"]}>
      <span>Sorry, no matches found for your search.</span>
    </div>
  );
};

export default SearchResults;
