import React from "react";
import "../../../../styles/scss/smartpathV2/resources/resourcesLinkout.scss";
import {ReactComponent as ResourcePageArrow} from "../../../../resources/images/resource-page-arrow.svg";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
import { openLink } from "../../sharedComponents/utils";

const ResourcesLinkout = ({ data }) => {

  const handleLinkoutAnalytics = () => {
    openLink(data.adPlannerUrl);
    clickAnalytics({
      category: 'Resources',
      label: 'Visit Ad Planner'
    });
  };

  return (
    <div className="linkout-container">
       <p>{`${data.normalText} `}<span className="linkout" onClick={()=> handleLinkoutAnalytics()} >{data.linkOutText}</span> <ResourcePageArrow className="arrow"/> </p> 
    </div>
    
  );
};

export default ResourcesLinkout;
