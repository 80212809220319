import React, {useState, useEffect} from 'react';
import applyCustomMarkdown from '../../../screens/utils/applyCustomMarkdown.js';
import '../../../styles/css/marketingPage/testimonialSection.css';
import Testimonial from './testimonial';
import { FormattedMessage } from "react-intl";
import { ClipLoader } from "react-spinners";
import AppSettings from "../../../appsettings";
import ReactFreezeframe from 'react-freezeframe';
import VideoPlayerComponent from '../../../screens/videoPlayer/videoPlayer.component';
import { testimonialLink } from '../../../screens/certification/certification.analytics.js';

function TestimonialSection (props) {

    const [displayedTestimonials, setDisplayedTestimonials] = useState([]);
    const [remainingTestimonials, setRemainingTestimonials] = useState([]);
    const [widgetHeight, setWidgetHeight] = useState(287);

    useEffect(() => {
        if (props.testimonials) {
          loadMoreTestimonials(3);
          setRemainingTestimonials(props.testimonials.length);
        }

        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () =>
            window.removeEventListener("resize", updateDevice);
      }, [props.testimonials]);

      const updateDevice = () => {
        if (window.innerWidth >= 1320) {
            setWidgetHeight(287);
        } else if (window.innerWidth >= 768) {
            setWidgetHeight(287);
        } else if (window.innerWidth < 768) {
            setWidgetHeight(179);
        }
    }

      const loadMoreTestimonials = (totalTestimonials) => {
        const numTestimonialsToDisplay = totalTestimonials || displayedTestimonials.length + 3;
        setDisplayedTestimonials(props.testimonials.slice(0, numTestimonialsToDisplay));
        setRemainingTestimonials(props.testimonials.length - displayedTestimonials.length);
      };

      const isVideoImageOrGif = function(qumuId,contentImage,contentGif){
       
        if(qumuId){
            return <VideoPlayerComponent
                analyticsModule={props.videoAnalytics && props.videoAnalytics.module}
                analyticsSection={props.videoAnalytics && props.videoAnalytics.section}
                analyticsTag={props.videoAnalytics && props.videoAnalytics.videoTagId}
                item={{
                  qumuId: qumuId,
                  pageTitle: props.videoAnalytics && props.videoAnalytics.page,
                  videoTitle: 'marketing page video',
                  contentTitle: 'marketing',
                  hideCTA: false,
                  height: widgetHeight
                }}
        />
        }
        else if(contentImage){
            return <div><img className="image" src={props.imagePath + contentImage}/></div>
        }
        else if(contentGif){
            return <div><ReactFreezeframe className="image" src={props.imagePath + contentGif}/></div>
        }
        else return
    }

    const getQuoteImage = function(type){
        const leftQuoteImage = AppSettings.AWSImgRepo.resources + 'en/left_quote_red.svg';
        const rightQuoteImage = AppSettings.AWSImgRepo.resources + 'en/right_quote_red.svg';

        if(type === "left"){
            return <div><img src={leftQuoteImage}/></div>
        }else if(type === "right"){
            return <div><img src={rightQuoteImage}/></div>
        }else{
            return;
        }
    }

  return ( 
      <div className="testimonialSection">
  <div className="sectionContainer">
      <div className="sectionTitle">{applyCustomMarkdown(props.title, "bold")}</div>
      <div className="mediaQuoteContainer">
      <div className="mediaDisplay">{isVideoImageOrGif(props.videoID, props.image, props.gif)}</div>
      <div className="bigQuoteCard">
        <div className="leftQuote">{getQuoteImage("left")}</div>
        <div className="bigQuote">
          {applyCustomMarkdown(props.bigQuote.quote, "bold")}
        </div>
        <div className="bigQuoteAuthor">
          <div>{props.bigQuote.author && "- " + props.bigQuote.author}</div>
          <div>{props.bigQuote.authorTitle || ""}</div>
        </div>
        <div className="rightQuote">{getQuoteImage("right")}</div>
      </div>
      </div>
      { props.testimonials ?
      <div className="testimonialDisplay">
      {displayedTestimonials.map((testimonial, index) => {
        return <Testimonial
          key={index}
          quote={testimonial.quote}
          author={testimonial.author}
          authorTitle={testimonial.authorTitle}
        />;
      })}
      </div>: (
        <div className="newsSpinner">
          <ClipLoader color={"#EB0A1E"} loading={true} size={50} />
        </div>
      )}
      <div className="loadMore">
        {remainingTestimonials > 3 ? (
          <div className="viewMore" onClick={() => { loadMoreTestimonials(); testimonialLink(props.clickAnalytics, "See More Testimonials")}}>
            <FormattedMessage id="See More Testimonials" />
          </div>
        ) : displayedTestimonials.length > 3 ? (
          <div className="button secondary" id="noMoreButton">
            <FormattedMessage id="global.button.noMoreToDisplay" />
          </div>
        ) : <div/>}
      </div>
  </div>
  </div>
  )};


export default TestimonialSection;