import React from "react";
import AppSettings from "../../../../appsettings";
import S from "../../../../styles/scss/smartpathV2/resources/releaseNotesTile.module.scss";
import applyMarkdown from "../../../utils/applyMarkdown";
import ResourceButton from "./resourceButton";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
import { openPdf } from "../../sharedComponents/utils";

const ReleaseNotesTile = ({ image, date, category, description, pdfFile, spotlight, sectionTitle }) => {
  
  const handlePDFClickAnalytics = (pdf) => {
    clickAnalytics({
      category: 'Resources-Release Notes',
      label: pdf ? pdf + ' PDF': '',
      container: sectionTitle ? sectionTitle : '',
      contentTitle: category
    });
  };

  const handleButtonClick = () => {
    handlePDFClickAnalytics(pdfFile);
    openPdf(pdfFile);
  };

  return (
    <div className={`${S["release-notes-tile"]} ${spotlight ? S["spotlight"] : ""}`}>
      <div className={S["image-container"]}>
        <img
          src={`${AppSettings.AWSImgRepo.smartPathV2}${image}`}
          alt={category}
        />
        <div className={S["overlay"]}>
          <ResourceButton
            buttonText={"Read PDF"}
            clickFunction={handleButtonClick}
          />
        </div>
      </div>

      <div className={S["text-container"]}>
        <div className={S["date"]}>{date}</div>
        <div className={S["title"]}>{category}</div>
        <div className={S["description"]}>
          {applyMarkdown(description)}
        </div>
      </div>
    </div>
  );
};

export default ReleaseNotesTile;
