import React, { useState } from "react";
import ResourcesTabs from "./components/resourcesTabs.js";
import TrainingContent from "./components/trainingContent.js";
import ReleaseNotes from "./components/releaseNotes.js";
import S from "../../../styles/scss/smartpathV2/resources/smartpathResources.module.scss";
import MarketingAssets from "./components/marketingAssets.js";
import ResourcesLinkout from "./components/resourcesLinkout.js";
import SmartpathSupportServices from "./components/smartpathServiceSupport.js";
import { clickAnalytics } from "../sharedComponents/smartpath.analytics.js";

const SmartPathResources = ({resourcesData, sharedData}) => {
  const resourceTabs = [
    "Training Content",
    "Release Notes",
    "Marketing Assets",
  ];

  const [shouldResetReleaseNotes, setShouldResetReleaseNotes] = useState(false);
  const [selectedTab, setSelectedTab] = useState(resourceTabs[0]);

  const triggerReleaseNotesReset = () => setShouldResetReleaseNotes(!shouldResetReleaseNotes);

  const handleTabChangeAnalytics = (content) => {
    clickAnalytics({
      category: 'Resources',
      label: 'Resources Tab',
      contentTitle: content
    });
  };

  const handleSetSelectedTab = (tab) => {
    if (tab === "Release Notes" && selectedTab === tab) {
      triggerReleaseNotesReset();
      return;
    }
    setSelectedTab(tab);
    handleTabChangeAnalytics(tab);
  };

  const getResourcesComponent = () => {
    switch (selectedTab) {
      case "Training Content":
        return <TrainingContent trainingContentData={resourcesData.trainingContent} />;
      case "Release Notes":
        return <ReleaseNotes releaseNotesData={resourcesData.releaseNotes} />
      case "Marketing Assets":
        return <MarketingAssets marketingAssestsData={resourcesData.marketingAssets} miscData={resourcesData.resourcesMisc} />;
      default:
        return <TrainingContent trainingContentData={resourcesData.trainingContent} />;
    }
  };

  return (
    <div
      className={S["smartpath-resources-page"]}
      key={shouldResetReleaseNotes}
    >
      <ResourcesLinkout data={resourcesData.resourcesMisc} />
      <ResourcesTabs
        tabData={resourceTabs}
        selectedTab={selectedTab}
        setSelectedTab={handleSetSelectedTab}
      />
      {getResourcesComponent()}
      <SmartpathSupportServices data={sharedData.supportComponent}/>
    </div>
  );
};

export default SmartPathResources;
