import React, { useState } from 'react';
import AppSettings from "../../../../appsettings.js";
import { isMobileView } from '../../../utils/getScreenSize.js';
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/results.scss";
import { clickAnalytics } from '../../sharedComponents/smartpath.analytics.js';

const Results = ( {data} ) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const ParentTile = ({ categoryTitle, categoryDescription, categoryColor, categoryLabel, categoryLabelColor, isExpanded, onToggle }) => {
    const arrowSrc = AppSettings.AWSImgRepo.resources + (isExpanded ? "en/ExpandedArrow.png" : "en/CollapsedArrow.png");

    const handleTileClickAnalytics = (categoryTitle, isExpanded) => {
      clickAnalytics({
        category: 'What is SmartPath',
        label: 'Product Reason Tab' + (!isExpanded ? ' Expand' : ' Collapse'),
        contentTitle: categoryTitle
      });
    };

    const handleClick = () => {
      onToggle();
      handleTileClickAnalytics(categoryTitle, isExpanded);
    }

    return (
      <div className="parent-tile"
        onClick={handleClick}
        style={isExpanded ? { backgroundColor: categoryColor, color: "#FFFFFF" } : {}}
      >
        <div className="tile-label" style={{ backgroundColor: categoryLabelColor }}>{categoryLabel}</div>
        <div className='top-section'>
          <div className="parent-tile-content">
            <div className='parent-tile-title'>{categoryTitle}</div>
            <div className='parent-tile-description'>{categoryDescription}</div>
          </div>
        </div>
        <img className="tile-arrow" src={arrowSrc} alt="Arrow" />
      </div>
    );
  };

  const ChildTile = ({ resultTitle, resultStat, resultDescription }) => {
    return (
      <div className="child-tile">
        <div className="child-tile-category">{data.categories[expandedIndex].categoryTitle.toUpperCase()}</div>
        <div className='child-tile-title'>{resultTitle}</div>
        <p className="stat" style={{ color: data.categories[expandedIndex].categoryColor }}>{resultStat}</p>
        <div className="child-tile-description">{resultDescription}</div>
      </div>
    );
  };

  const renderChildrenTiles = () => {
    return (
      <div className="child-tiles-container" style={{ backgroundColor: data.categories[expandedIndex].categoryColor }}>
        {data.categories[expandedIndex].results?.map(child =>
          <ChildTile
            resultTitle={child.resultTitle}
            resultStat={child.resultStat}
            resultDescription={child.resultDescription}
          />)
        }
      </div>
    );
  };

  return (
    <div className='results-container'>
      <div className="results-header">{data.componentTitle}</div>
      <div className="results-tile-container">
        {data.categories.map((tile, index) => (
          <>
            <ParentTile
              key={index}
              categoryTitle={tile.categoryTitle}
              categoryDescription={tile.categoryDescription}
              categoryColor={tile.categoryColor}
              categoryLabel={tile.categoryLabel}
              categoryLabelColor={tile.categoryLabelColor}
              isExpanded={expandedIndex === index}
              onToggle={() => setExpandedIndex(expandedIndex === index ? null : index)}
            />

            {isMobileView(window.innerWidth) && expandedIndex === index && (
              renderChildrenTiles()
            )}
          </>
        ))}
      </div>

      {!isMobileView(window.innerWidth) && expandedIndex !== null && (
        renderChildrenTiles()
      )}
    </div>
  );
};

export default Results;
