import React, { useEffect, useState } from "react";
import S from "../../../../styles/scss/smartpathV2/resources/releaseNotes.module.scss";
import PreviousReleaseNotes from "./previousReleaseNotes";
import ResourceSearchBar from "./resourceSearchBar";
import SearchResults from "./searchResults";
import SpotlightedReleaseNotes from "./spotlightedReleaseNotes";

const ReleaseNotes = ({releaseNotesData}) => {
  const [searchText, setSeachText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    handleSearchReleaseNotes();
  }, [searchText]);

  const sanitizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase();
  };

  const handleSearchReleaseNotes = () => {
    const cleanedSearchText = sanitizeText(searchText);
    if (!cleanedSearchText) {
      setSearchResults([]);
      return;
    }
    const searchReleaseNotes = releaseNotesData.flatMap((note) => {
      const fullString =
        note.description + note.date + note.category;
      const cleanedString = sanitizeText(fullString);
      return cleanedString.includes(cleanedSearchText) ? note : [];
    });

    setSearchResults(searchReleaseNotes);
  };

  return (
    <div className={S["smartpath-release-notes-container"]}>
      <ResourceSearchBar
        value={searchText}
        setValue={setSeachText}
        placeholder="Search"
      />
      {searchText ? (
        <SearchResults
          searchText={searchText}
          results={searchResults}
          clear={() => setSeachText("")}
        />
      ) : (
        <>
          <SpotlightedReleaseNotes
            title={"What's New"}
            items={releaseNotesData.filter(note => note.spotlighted)}
          />
          <PreviousReleaseNotes
            title={"Previous Releases"}
            items={releaseNotesData.filter(note => !note.spotlighted)}
          />
        </>
      )}
    </div>
  );
};

export default ReleaseNotes;
