import React from "react";
import TrainingContentCarousel from "./trainingContentCarousel";
import "../../../../styles/scss/smartpathV2/resources/trainingContent.scss"

const TrainingContent = ({trainingContentData}) => {
  const uniqueCategories = [...new Set(trainingContentData.map(item => item.category))];
  
  return (
    <div className="smartpath-training-content-container">
      {uniqueCategories.map(category => 
        <TrainingContentCarousel title={category} data={trainingContentData.filter(item => item.category === category)} />
      )}
    </div>
  );
};

export default TrainingContent;
