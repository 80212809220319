import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WindowSize } from "../../components/contexts/windowSizeContext";
import SubheaderV2 from "../subheaderV2/subheaderV2";
import SmartPathWelcome from "./welcome/smartPathWelcome";
import WhatIsSmartPath from "./whatIsSmartPath/whatIsSmartPath";
import SmartPathGetStarted from "./getStarted/smartPathGetStarted";
import SmartPathResources from "./resources/smartPathResources";
import SmartPathHeader from "./sharedComponents/smartPathHeader";
import { SmartPathContext } from "./sharedComponents/smartPathContext";
import { PageLoadAnalytics } from "./sharedComponents/smartpath.analytics.js";
import VideoModal from "./sharedComponents/videoModal";
import getMisc from "../../services/miscService.js";
import '../../styles/css/smartpathV2/smartpath.css';

const SmartPath = () => {
  const params = useParams();
  const history = useHistory();
  const { device } = useContext(WindowSize);
  const [currentTab, setCurrentTab] = useState(null);
  const [videoModalId, setVideoModalId] = useState(null);
  const [smartPathData, setSmartPathData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMisc('SPV2').then((data) => {
      setSmartPathData(data.items);
      setLoading(false);
    })
  }, []);

  const subheaderData = [
    { id: "Welcome", urlPath: "welcome", subcategory: "Welcome" },
    { id: "What Is SmartPath?", urlPath: "what-is-smartpath", subcategory: "What Is SmartPath?" },
    { id: "Get Started", urlPath: "get-started", subcategory: "Get Started" },
    { id: "Resources", urlPath: "resources", subcategory: "Resources" },
  ];

  useEffect(() => {
    const currentPageData = subheaderData.find((item) => item.urlPath === params.page);

    if (currentPageData) {
      setCurrentTab(currentPageData);
      PageLoadAnalytics({ page: currentPageData?.id?.replace(/[^\w\s]/g, '') });
    } else {
      history.replace("/smartPath/welcome");
    }
  }, [params.page]);

  const getHeaderQumuId = (headerData) => {
    const videoModalData = smartPathData?.shared.videoModals.find(modal => modal.videoModalId === headerData.videoModalId);
    const qumuIDs = videoModalData?.qumuIDs;
    
    if(qumuIDs.length > 0) {
      return qumuIDs[0];
    }
  };

  const getSmartPathComponent = () => {
    switch (currentTab?.urlPath) {
      case "welcome":
        return (
          <SmartPathWelcome
            data={smartPathData.welcomePage}
          />
        );
      case "what-is-smartpath":
        return (
          <WhatIsSmartPath 
            whatIsSmartPathData={smartPathData.whatIsSmartPathPage}
            sharedData={smartPathData.shared}
          />
        );
      case "get-started":
        return (
          <SmartPathGetStarted
            getStartedData={smartPathData.gettingStartedPage}
            sharedData={smartPathData.shared}
          />
        );
      case "resources":
        return (
          <SmartPathResources
            resourcesData={smartPathData.resourcesPage}
            sharedData={smartPathData.shared}
          />
        );
      default:
        return (
          <SmartPathWelcome
            data={smartPathData.welcomePage}
          />
        );
    }
  };

  return (!loading &&
    <div className="smartpath-page">
      <SubheaderV2
        title={"SmartPath"}
        baseUrl="/smartPath"
        device={device}
        tabs={subheaderData}
        mobileTabs={subheaderData}
        currentTab={currentTab}
        mobileVerticalMenu
        bookmarkable
      />
      <SmartPathContext.Provider value={{ setVideoModalId: setVideoModalId, currentTabId: currentTab ? currentTab.id : null }}>
        <SmartPathHeader
          data={smartPathData?.shared.pageHeaders.find(header => header.page === currentTab.id)}
          qumuId={getHeaderQumuId(smartPathData?.shared.pageHeaders.find(header => header.page === currentTab.id))}
        />
        <div className="smartpath-page-content">
          {getSmartPathComponent()}
        </div>
        <VideoModal
          key={videoModalId}
          data={smartPathData?.shared.videoModals.find(modal => modal.videoModalId === videoModalId)}
          open={videoModalId !== null}
          onCloseModal={() => setVideoModalId(null)}
        />
      </SmartPathContext.Provider>
    </div>
  );
};

export default SmartPath;
