import React from "react";
import StickyTabs from "../sharedComponents/stickyTabs";
import ReadyToSignUp from "./components/readyToSignUp.js";
import Results from "./components/results.js";
import Roadmap from "./components/roadmap.js";
import SmartpathSupportServices from "../resources/components/smartpathServiceSupport.js";

import { generateTabContentContainer, shouldExtendToEdgeOfScreen } from "../sharedComponents/utils.js";

const WhatIsSmartPath = ({whatIsSmartPathData, sharedData}) => {

  return (
    <div className="smartpath-what-is-smartpath-page">
        <Roadmap data={whatIsSmartPathData.ecosystemComponent} analyticsActions={{ onTabClick: () => {} }} />
        <Results
          data={whatIsSmartPathData.resultsComponent}
        />
        {whatIsSmartPathData.whatIsSmartPathTabs.map(tabGroup => 
          <StickyTabs
            title={tabGroup.componentTitle}
            tabs={generateTabContentContainer(tabGroup.tabs, sharedData.children, tabGroup.componentTitle)}
            extendToScreenEdge={shouldExtendToEdgeOfScreen(tabGroup.tabs, sharedData.children)}
          />
        )}
        <SmartpathSupportServices data={sharedData.supportComponent}/>
        <ReadyToSignUp
          data={whatIsSmartPathData.readyToSignUp}
        />
    </div>
  );
};

export default WhatIsSmartPath;