import React from "react";
import "../../../../styles/scss/smartpathV2/resources/resourcesTabs.scss";

const ResourcesTabs = ( {tabData, selectedTab, setSelectedTab} ) => {

  return (
    <div className="resource-tabs-wrapper">
      <div className="resources-tabs-container">
        {tabData.map((tab) => (
          <div key={tab} className={`resource-tab ${selectedTab === tab ? "selected" : ""}`} onClick={() => setSelectedTab(tab)}>
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourcesTabs;
