import { ReactComponent as HorizontalArrow } from "../../../../resources/images/horizontal-arrow.svg";
import S from "../../../../styles/scss/smartpathV2/resources/releasePagination.module.scss";

const ReleasePagination = ({
  currentPage,
  handlePrevPage,
  totalPages,
  handleNextPage,
}) => {
  return (
    <div className={S["pagination-container"]}>
      <HorizontalArrow
        className={`${S["arrow-buttons"]} ${S["left-arrow"]} ${
          currentPage === 1 ? S["arrow-disabled"] : ""
        }`}
        onClick={handlePrevPage}
      />
      <div className={S["page-number-container"]}>
        {" "}
        {currentPage} of {totalPages}{" "}
      </div>
      <HorizontalArrow
        className={`
          ${S["arrow-buttons"]} +
          ${currentPage === totalPages ? S["arrow-disabled"] : ""}
        `}
        onClick={handleNextPage}
      />
    </div>
  );
};

export default ReleasePagination;
