import React from 'react';
import ReactDOM from 'react-dom';
import ShareModalButtonWrapper from '../../../screens/shareModal/shareModalButtonWrapper';
import { ReactComponent as ShareIcon } from "../../../resources/images/share-icon.svg";

export function addShareButton(videojs, player, linkToBeShared) {
  //  Register the custom Share component:
  const Component = videojs.getComponent('Component');

  class ShareModalComponent extends Component {
    constructor(player, options) {
      super(player, options);
      this.el_ = this.createEl();
    }

    createEl() {
      return super.createEl('div', {
        style: 'display: contents'
      });
    }

    mounted() {
      ReactDOM.render(
        <ShareModalButtonWrapper
          linkToBeShared={linkToBeShared}
          analyticsFunction={() => console.log('Modal opened')}
        >
          <button className='vjs-control vjs-button' style={{ cursor: "pointer" }}>
            <span className='vjs-svg-icon'>
              <ShareIcon height="16" width="16" color="#FFF" />
            </span>
          </button>
        </ShareModalButtonWrapper>,
        this.el_
      );
    }

    dispose() {
      ReactDOM.unmountComponentAtNode(this.el_);
      super.dispose();
    }
  }

  videojs.registerComponent('ShareModalComponent', ShareModalComponent);

  // Add it to the player control bar
  const shareComponent = player.getChild('controlBar')
    .addChild('ShareModalComponent', {}, 11);

  // Mount the React-based component
  shareComponent.mounted();
}