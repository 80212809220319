import { GlobalAnalytics } from '../../../analytics';
import { postWatchVideoAction } from '../../../services/nitroService';
import { postUserActivityRecord } from '../../../services/userActivityService';
const analytics = new GlobalAnalytics();

// Fire Analytics on Play, 25%, 50%, 75%, and Video End
export function registerAnalyticsPlugin(videojs) {
  if (!videojs.getPlugin('AnalyticsPlugin')) {
    videojs.registerPlugin('AnalyticsPlugin',
      function (analyticsObject = {}) {
        const {
          analyticsModule = "Video",
          analyticsSection = "Engage Live",
          analyticsPage = "Video",
          analyticsTag = "40.5",
          analyticsVehicleSpecs = null,
          videoDuration = "",
          title = "",
          id = ""
        } = analyticsObject;

        const player = this;
        const milestones = [25, 50, 75, 100];
        const milestonesReached = { 25: false, 50: false, 75: false, 100: false };

        player.on('play', () => {
          console.log('Analytics: User pressed play');
          fireVideoAnalytics(analyticsPage, title, id, 'event71', 'Video Play Click', analyticsModule, analyticsSection, analyticsTag, '0', videoDuration, true, analyticsVehicleSpecs);
        });

        player.on('timeupdate', () => {
          const currentTime = player.currentTime();
          const duration = player.duration();
          if (duration > 0) {
            const progress = Math.floor((currentTime / duration) * 100);
            milestones.forEach((milestone) => {
              if (!milestonesReached[milestone] && progress >= milestone) {
                milestonesReached[milestone] = true;
                console.log(`Analytics: Video reached ${milestone}%`);
                fireVideoAnalytics(analyticsPage, title, id, 'event73', `Video ${milestone}%`, analyticsModule, analyticsSection, analyticsTag, `${milestone}`, videoDuration, false, analyticsVehicleSpecs);
              }
            });
          }
        });

        player.on('ended', () => {
          if (!milestonesReached[100]) {
            milestonesReached[100] = true;
            console.log('Analytics: Video ended (100%)');
            fireVideoAnalytics(analyticsPage, title, id, 'event72', 'Video Complete', analyticsModule, analyticsSection, analyticsTag, '100', videoDuration, false, analyticsVehicleSpecs);
          }
        });
      });
  }
}

const fireVideoAnalytics = (pageTitle, title, id, event, category, module, section, tag, percentWatched, videoLength, sendPlayClick, vehicleSpecs) => {
  // Send Nitro
  postWatchVideoAction(id, percentWatched);

  // Send to User Activity on Play Click
  if (sendPlayClick) {
    const videoPath = `${window.location.pathname}#${id}`;
    postUserActivityRecord(videoPath, true, title);
  }

  // Send to Adobe
  analytics.fireOnClick({
    tag,
    section,
    page: pageTitle,
    category,
    contentTitle: title,
    mediaTitle: title,
    modelName: ' ',
    module,
    label: title,
    events: event,
    videoLength: formatDuration(videoLength),
    ...vehicleSpecs,
  });
};

export function formatDuration(milliseconds) {
  const hh = Math.floor((milliseconds / 1000 / 60 / 60) % 60);
  const mm = Math.floor((milliseconds / 1000 / 60) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const ss = Math.floor((milliseconds / 1000) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  if (hh) {
    return `${hh}:${mm}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
}