import React from "react";
import Appsettings from "../../../../appsettings.js";
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/logoShowcase.scss";

const LogoShowcase = ({ data }) => {
  return (
    <div className="logo-showcase-component">
      <h2 className="logo-showcase-header">{data.componentTitle}</h2>
      <div className="logo-container-group">
        {data.sections.map((section, index) => (
          <div key={index} className="logo-container">
            <div className="text-section">
              <div className="group-title">{section.sectionName}</div>
              <div className="group-description">{section.sectionDescription}</div>
            </div>
            <div className="image-section">
              {section.logos.map((image, imageIndex) => (
                <div className="logo-image-wrapper">
                  <img
                    key={imageIndex}
                    src={Appsettings.AWSImgRepo.smartPathV2 + image}
                    alt={`Logo ${imageIndex + 1}`}
                    className="image-item"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoShowcase;