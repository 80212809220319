import React from "react";
import ReleaseNotesTile from "./releaseNotesTile";
import "../../../../styles/scss/smartpathV2/resources/spotlightedReleaseNotes.scss"

const SpotlightedReleaseNotes = ( {title, items} ) => {

  return (
    <div className="spotlighted-release-notes-container">
        <div className="container-title">{title}</div>
        <div className="release-notes-section">
            {items?.map(item => 
                <React.Fragment key={item.id}>
                  <ReleaseNotesTile
                    image={item.image}
                    date={item.date}
                    category={item.category}
                    description={item.description}
                    pdfFile={item.pdfFile}
                    spotlight={true}
                    sectionTitle={title}
                />
                </React.Fragment>
            )}
        </div>
    </div>
  );
};

export default SpotlightedReleaseNotes;
