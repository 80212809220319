import React, { useEffect } from 'react';
import '../../../styles/css/marketingPage/videoPage.css';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';
import { appPageLoad } from '../appPage-analytics';


function AppPageVideos (props) {
    const pageLoadAnalytics = props.analyticsData && props.analyticsData.pageLoad;
    const videoAnalytics = props.analyticsData && props.analyticsData.videoAnalytics.howToVideos;
    useEffect(()=>{
        appPageLoad(pageLoadAnalytics, "How-To Videos");
    }, []);
    return (
        <div className="container">
            <span className="videoTitle">{props.videoData.pageTitle}</span>
            <div className="videoContent">
                <VideoPlayerComponent
                    analyticsModule={videoAnalytics && videoAnalytics.module}
                    analyticsSection={videoAnalytics && videoAnalytics.section}
                    analyticsTag={videoAnalytics && videoAnalytics.videoTagId}
                    item={{
                        hideCTA: false,
                        pageTitle: videoAnalytics && videoAnalytics.page,
                        qumuId: props.videoData.id,
                    }} 
                    playlist={true}
                />
            </div> 
        </div>
    );
};

export default AppPageVideos;