import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getExplicitRecommendedArticles(modelName, modelYear) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/explicit-recommended-articles/${modelName}/${modelYear}`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}
