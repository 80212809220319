import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import FlatIconCard from '../../components/molecules/cards/flatIconCard';
import HeroImage from '../../components/molecules/heroImage';
import applyMarkdown from '../utils/applyMarkdown';
import { isMobileView } from '../utils/getScreenSize';
import { certificationPageLoaded, requirementsRulesSheetsClick } from './certification.analytics';
import '../../styles/scss/certificationRequirements.scss';
import * as _ from 'lodash';


const certificationRequirements = (props) => {
  const [disclaimer, setDisclaimer] = useState(null);
  const disclaimerRef = useRef();

  useEffect(() => {
    setDisclaimer(disclaimerRef.current);
  }, [disclaimerRef]);

  useEffect(() => {
    certificationPageLoaded(props.pageLoadAnalytics, "Requirements");
  }, []);
  const handleRuleClick = (ruleUrl, ruleType, role, isSpanish) => {
    if (ruleUrl) {
      window.open(ruleUrl, '_blank');
    }
    if (props.clickAnalytics && props.clickAnalytics.rulesSheets) {
      const startCaseRuleType = _.startCase(_.lowerCase(ruleType));
      const ruleClickAction = _.replace(props.clickAnalytics.rulesSheets.actionNeedsReplace, props.clickAnalytics.rulesSheets.toReplaceRuleType, startCaseRuleType);
      const ruleClickActionWithRole = _.replace(ruleClickAction, props.clickAnalytics.rulesSheets.toReplaceRole, role) + (isSpanish ? ' Spanish' : '');
      requirementsRulesSheetsClick(
        props.clickAnalytics,
        props.pageName,
        props.clickAnalytics.rulesSheets.category,
        ruleClickActionWithRole,
      );
    }
  };

  const Modules = (content) => {
    let leftColumn = [];
    let rightColumn = [];

    if (content) {
      content.modules && content.modules.map(mod => {
        if (mod.column === '1') {
          leftColumn.push(ModuleCard(mod));
        } else {
          rightColumn.push(ModuleCard(mod));
        }
      });
  
      const rulesSheetsLinks = content.rulesSheets && (
        <div className="requirements-rules-sheets">
          {content.rulesSheets.variableOps && <div className="requirements-rules-variable-ops">
            {RuleSheet(content.rulesSheets.variableOps)}
          </div>}
          {content.rulesSheets.variableOps && <div className="requirements-rules-fixed-ops">
            {RuleSheet(content.rulesSheets.fixedOps)}
          </div>}
        </div>
      );

      rightColumn.push(rulesSheetsLinks);
    };

    return (
      <div className="requirements-modules">
        <div className="requirements-modules-left-column">
          {leftColumn}
        </div>
        <div className="requirements-modules-right-column">
          {rightColumn}
        </div>
      </div>
    );
  };

  const ModuleCard = (mod) => (
    mod && (<div key={mod.title} className="requirements-module">
      <FlatIconCard
        description={applyMarkdown(mod.description, disclaimer)}
        footnote={mod.footnote}
        iconFile={mod.iconFile}
        imagePath={props.imagePath}
        title={mod.title}
      />
    </div>)
  );

  const RuleSheet = (rules) => (
    <React.Fragment>
      <div className="requirements-rules-title">
        {rules.title}
      </div>
      <div className="requirements-rules-items">
        {rules.links && rules.links.map(rule => {
          let englishRuleUrl = null;
          let spanishRuleUrl = null;
          if (rule.englishFileName && rule.englishFileName !== '') {
            englishRuleUrl = props.filePath + rule.englishFileName;
          }
          if (rule.spanishFileName && rule.spanishFileName !== '') {
            spanishRuleUrl = props.filePath + rule.spanishFileName;
          }

          return (
            <div key={rule.title} className="requirements-rules-item">
              <div>
                <div className="requirements-rules-item-title">
                  {rule.title}
                </div>
                <div className="requirements-rules-item-date">
                  {rule.linkDate}
                </div>
                <div>
                  {englishRuleUrl && <a className="requirements-rules-item-link" onClick={() => handleRuleClick(englishRuleUrl, rules.title, rule.title, false)} target="_blank">English</a>}
                  {spanishRuleUrl && <a className="requirements-rules-item-link" onClick={() => handleRuleClick(spanishRuleUrl, rules.title, rule.title, true)} target="_blank">Spanish</a>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <HeroImage
        description={applyMarkdown(props.requirementsData.description, disclaimer)}
        headline={props.requirementsData.headline}
        hideImage={isMobileView(window.innerWidth)}
        image={props.imagePath + props.requirementsData.heroImage}
      />
      {Modules(props.requirementsData.content)}
      <DisclaimersComponent
        ref={(instance) => { disclaimerRef.current = instance; }}
        template={props.disclaimer}
      />
    </React.Fragment>
  );
};

certificationRequirements.propTypes = {
  clickAnalytics: PropTypes.shape({
    page: PropTypes.string,
    rulesSheets: PropTypes.shape({
      actionNeedsReplace: PropTypes.string,
      category: PropTypes.string,
      toReplaceRuleType: PropTypes.string,
      toReplaceRole: PropTypes.string,
    }),
    section: PropTypes.string,
    tagId: PropTypes.string,
  }),
  disclaimer: PropTypes.string,
  filePath: PropTypes.string,
  imagePath: PropTypes.string,
  pageName: PropTypes.string,
  requirementsData: PropTypes.shape({
    content: PropTypes.shape({
      rulesSheets: PropTypes.shape({
        fixedOps: PropTypes.shape({
          links: PropTypes.arrayOf(PropTypes.shape({
            fileName: PropTypes.string,
            linkDate: PropTypes.string,
            tile: PropTypes.string,
          })),
          title: PropTypes.string,
        }),
        variableOps: PropTypes.shape({
          links: PropTypes.arrayOf(PropTypes.shape({
            fileName: PropTypes.string,
            linkDate: PropTypes.string,
            tile: PropTypes.string,
          })),
          title: PropTypes.string,
        }),
      }),
      modules: PropTypes.arrayOf(PropTypes.shape({
        column: PropTypes.string,
        footnote: PropTypes.string,
        description: PropTypes.string,
        iconFile: PropTypes.string,
        title: PropTypes.string,
      }))
    }),
    description: PropTypes.string,
    headline: PropTypes.string,
    heroImage: PropTypes.string,
  }),
};

export default certificationRequirements;
