import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import '../../styles/css/towingPayload-calculatorModal.css'
import VideoPlayerComponent from '../videoPlayer/videoPlayer.component';
import RedLine from '../../components/atoms/RedLine';
import SearchBarInput from '../../components/molecules/searchBarInput';
import AppSettings from '../../appsettings';
import { menuCloseClick, openMenuModal } from './towingPayload-analytics';
import isAppleMobileDevice from '../utils/isAppleMobileDevice';
import SearchFeedback from '../../components/molecules/search-feedback';
import ScrollingModal from '../../components/molecules/scrollingModal';
import { TowingPayloadAccordion } from './components/towingPayload-accordion'
import '../../styles/css/towingPayload-accordion.css'

export const CalculatorModal = (props) => {
    const [modalTitle, setModalTitle] = useState("");
    const [searchParam, setSearchParam] = useState("");
    const [showSearchBar, setShowSearchBar] = useState(null);
    const [accordionData, setAccordionData] = useState([]);

    useEffect(() => {
        if(props.visible) {
            setModalDetails(props.modalType)
            props.pageLoadAnalytics && openMenuModal(props.pageLoadAnalytics, props.vehicleSelection, props.trimSelection, props.modalType);
        }
    }, [props.visible]);

    const setModalDetails = (type) => {
        switch(type){
            case 'FAQs':
                setModalTitle("FAQs");
                setShowSearchBar(AppSettings.FeatureFlags.showTowingPayloadFAQsSearchBar);
                let formatFaqData = [{
                    content: props.faqData,
                    id: "1",
                    title: "FAQs"
                }]
                setAccordionData(formatFaqData);
                break;
            case 'Towing Definitions':
                setModalTitle("Towing Definitions");
                setShowSearchBar(AppSettings.FeatureFlags.showTowingPayloadDefinitionsSearchBar);
                setAccordionData(props.towingDefinitionsData);
                break;
            case 'Common Weights':
                setModalTitle("Common Weights");
                setShowSearchBar(AppSettings.FeatureFlags.showTowingPayloadCommonWeightsSearchBar);
                setAccordionData(props.commonWeightsData);
                break;
            case 'Videos':
                setModalTitle("Towing Videos");
                setShowSearchBar(false);
                break;
            default:
                break;
        };
    };


    const createAccordionContent = () => {
        return <TowingPayloadAccordion 
            data={accordionData} 
            searchParam={searchParam}
            accordionType={props.modalType}
            clickAnalytics={props.clickAnalytics} 
            vehicleSelection={props.vehicleSelection} 
            trimSelection={props.trimSelection} 
            page = {props.page}
            hideHeader = {props.modalType == "FAQs" ? true : false}
        />
    }

    const createVideoContent = (playlistId) => (
        <div className="videoContent">
            <VideoPlayerComponent
                analyticsModule={props.clickAnalytics && props.clickAnalytics.calculatorPage.videoAnalytics.module}
                analyticsPage={props.clickAnalytics && props.clickAnalytics.calculatorPage.videoAnalytics.page}
                analyticsSection={props.clickAnalytics && props.clickAnalytics.calculatorPage.section}
                analyticsVehicleSpecs={props.trimSelection && props.vehicleSelection && {
                    modelBed: props.trimSelection.bedLength,
                    modelCab: props.trimSelection.cab,
                    modelCode: props.trimSelection.modelCode,
                    modelDrivetrain: props.trimSelection.driveType,
                    modelEngine: props.trimSelection.engine,
                    modelName: props.vehicleSelection.modelName,
                    modelYear: props.vehicleSelection.modelYear,
                    trim: props.trimSelection.trimName, 
                }}
                item={{
                    height: 323,
                    hideCTA: false,
                    pageTitle: props.vehicleSelection && props.vehicleSelection.modelName,
                    qumuId: playlistId,
                }} 
                playlist={true}
            />
        </div> 
    );

    const getMaxWidth = () => {
        if(props.modalType == 'Videos') {
            return 1034
        } else {
            return 1360
        }
    };

    const getContent = (type) => {
        switch(type){
            case 'FAQs':
            case 'Towing Definitions':
            case 'Common Weights':
                return createAccordionContent();
            case 'Videos':
                return createVideoContent(props.videosData[0].id);
            default:
                break;
        };
    };

    const closeModal = () => {
        props.clickAnalytics && menuCloseClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.modalType, props.page);
        props.closeModal();
    };

    return (
        <ScrollingModal
            visible={props.visible}
            closeModal={()=>closeModal()} 
            title={modalTitle}
            width={props.modalType == 'Videos' ? 1034 : 1360}
            modalClassName="tp-menu-modal"
            openModalPageLoad={()=>props.pageLoadAnalytics && openMenuModal(props.pageLoadAnalytics, props.vehicleSelection, props.trimSelection, props.modalType)}
            closeModalClick={()=>props.clickAnalytics && menuCloseClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.modalType, props.page)}>
            <>
                {showSearchBar && <div className="searchbar-wrapper"><SearchBarInput placeholder={`Search ${modalTitle}`} onSearch={setSearchParam} dynamicSearching={true} autoFocus={true} /></div>}
                {getContent(props.modalType)}
                {showSearchBar && <SearchFeedback location='Towing & Payload' modal={modalTitle} searchParam={searchParam} />}
            </>
        </ScrollingModal>
    );
};