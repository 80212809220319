import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/smartpath-overview-testimonials.css';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';
import { isDesktopView, isMobileView, isTabletView } from '../../utils/getScreenSize';
import * as testimonialsAnalytics from '../overviewAnalytics/smartpath-overview-testimonials.analytics';
import useOnScreen from '../useIntersection';
import ReactFreezeframe from 'react-freezeframe';

export const SmartPathOverviewTestimonials = (props) => {
    const testimonialsRef = useRef();
    if (useOnScreen(testimonialsRef) && props.pageLoadAnalytics) {
        testimonialsAnalytics.testimonialsSubLoad(props.pageLoadAnalytics);
    }

    const testimonialSubQuotes = (content) => (
        content.map((quote, index) => (
            <div className='smartpath-overview-testimonials-subquote' key={index}>
                <div className='smartpath-overview-testimonials-subquote-text'>{applyMarkdown(quote.testimonialText)}</div>
                <div className='smartpath-overview-testimonials-subquote-name'>- {applyMarkdown(quote.name)}</div>
                <div className='smartpath-overview-testimonials-subquote-title'>{applyMarkdown(quote.employeeTitle)}</div>
            </div>
        ))
    );

    const getImage = ()=>{
        if (isDesktopView(window.innerWidth)){
            return props.data.highlightBackgroundImage
        }
        if (isTabletView(window.innerWidth)){
            return props.data.highlightTabletImage
        }
        if( isMobileView(window.innerWidth)){
            return props.data.highlightMobileImage
        }
    }
    const getImageHeight = ()=>{
        if (isDesktopView(window.innerWidth)){
            return props.data.highlightImageHeight
        }
        if (isTabletView(window.innerWidth)){
            return props.data.highlightTabletImageHeight
        }
        if (isMobileView(window.innerWidth)){
            return props.data.highlightMobileImageHeight
        }
    }
    const getImageWidth = ()=>{
        if (isDesktopView(window.innerWidth)){
            return props.data.highlightImageWidth
        }
        if (isTabletView(window.innerWidth)){
            return props.data.highlightTabletImageWidth
        }
        if (isMobileView(window.innerWidth)){
            return props.data.highlightMobileImageWidth
        }
    }

    const linkAnalytics = (linkText) => {
        if (props.clickAnalytics && linkText) {
            testimonialsAnalytics.linkClick(props.clickAnalytics, linkText);
        }
    };

    const isVideoOrGif = () =>
   {
       let testimonialVideo
       
       if(props.data.qumuId){
        testimonialVideo = (
            <VideoPlayerComponent 
                item={{
                qumuId: props.data.qumuId,
                videoTitle: 'SmartPath Overview Testimonial Video',
                contentTitle: 'Overview',
                hideCTA: false,
                pageTitle: props.clickAnalytics && props.clickAnalytics.testimonialsVideo.page,
                }}
            analyticsSection={props.clickAnalytics && props.clickAnalytics.testimonialsVideo.section}
            analyticsModule={props.clickAnalytics && props.clickAnalytics.testimonialsVideo.module}
            />
        );
       }
       else if(props.data.gif){
        testimonialVideo = <div className><ReactFreezeframe src={props.imagePath + props.data.gif} /></div> 
       }
       return testimonialVideo
   }

    return <div ref={testimonialsRef} className='smartpath-overview-testimonials'>
        <div className='smartpath-overview-testimonials-header'>{applyMarkdown(props.data.header)}</div>

        <div className='smartpath-overview-testimonials-highlight'>
            <div className='highlight-video'>
                {isVideoOrGif()}
            </div>
            <div className='highlight-quote' style={{ 'backgroundImage': `url(${props.imagePath + getImage()})`, 'height': getImageHeight(), 'width': getImageWidth(), 'background-size': `${getImageWidth()} ${getImageHeight()}` }}>

                <div className='highlight-text'>{applyMarkdown(props.data.highlightTestimonial)}</div>
                <div className='highlight-person'>
                    <div className='highlight-name'>- {applyMarkdown(props.data.highlightName)}</div>
                    <div className='highlight-title'>{applyMarkdown(props.data.highlightTitle)}</div>
                </div>
            </div>
        </div>
        {props.data.content[0].name && <div className='smartpath-overview-testimonials-quotes'>
            {testimonialSubQuotes(props.data.content)}
        </div>}
        <div className='smartpath-overview-testimonials-link'><a target='_blank' href={`${props.data.url}`} onClick={() => linkAnalytics(props.data.linkText)}>{`${props.data.linkText}`}</a></div>
    </div>;


};

SmartPathOverviewTestimonials.propTypes = {
    header: PropTypes.string,
    highlightName: PropTypes.string,
    highlightTestimonial: PropTypes.string,
    highlightTitle: PropTypes.string,
    linkText: PropTypes.string,
    url: PropTypes.string,
    qumuId: PropTypes.string,
    overviewTestimonialsData: PropTypes.object,
    disclaimer: PropTypes.string,
  };