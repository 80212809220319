import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/marketingPageMediaCard.css';
import VideoPlayerComponent from '../../../screens/videoPlayer/videoPlayer.component';
import { cardTileClick } from '../../../screens/certification/certification.analytics';
import { isExternalHref } from '../../../screens/utils/helpers';


const MarketingMediaCard = (props) => {
    const isVideoImageOrGif = (qumuId,contentImage,contentGif) => {
        if (qumuId) {
            return (
                <VideoPlayerComponent
                    analyticsModule={props.videoAnalytics && props.videoAnalytics.module}
                    analyticsSection={props.videoAnalytics && props.videoAnalytics.section}
                    analyticsTag={props.videoAnalytics && props.videoAnalytics.videoTagId}
                    item={{
                        qumuId: qumuId,
                        videoTitle: 'marketing page video',
                        contentTitle: 'marketing',
                        hideCTA: false,
                        pageTitle: "marketing page video"
                    }}
                />
            );
        } else if (contentImage) {
            return <div data-testid="bigCard-media-image"><img src={props.imagePath + contentImage}/></div>
        } else if (contentGif) {
            return <div data-testid="bigCard-media-gif"><img src={props.imagePath + contentGif}/></div>
        } else {
            return <></>
        }
    };

    const handleMediaCardClick = () => {
        cardTileClick(props.clickAnalytics, props.ctaText);        
        const target = isExternalHref(props.ctaHref) ? '_blank' : '_self';
        window.open(props.ctaHref, target);
    };
 
    return (   
        <div data-testid="marketing-media-card" className = "bigCard">
            <div className="bigCard-media">{isVideoImageOrGif(props.qumuId,props.image,props.gif)}</div>
            <div className="bigCard-content">
                <div className="bigCard-non-links">    
                    <div className = "bigCard-title">{props.title}</div>
                    <div className = "bigCard-description">
                        {applyMarkdown(props.description)}
                    </div>
                </div>
                {props.ctaText && <div className = "bigCard-links">
                    <a onClick={() => handleMediaCardClick()}>{props.ctaText}</a>
                </div>}
            </div>
        </div>
    );
}


MarketingMediaCard.propTypes = {
    qumuId: PropTypes.string,
    imagePath: PropTypes.string,
    image: PropTypes.string,
    gif: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    ctaHref: PropTypes.string,
    ctaText: PropTypes.string
}

export default MarketingMediaCard;
