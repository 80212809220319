import React, { useEffect, useState, useContext } from "react";
import AppSettings from "../../../appsettings";
import "../../../styles/scss/smartpathV2/sharedComponents/stickyTabs.scss";
import { SmartPathContext } from "../sharedComponents/smartPathContext";
import { clickAnalytics } from "./smartpath.analytics";

const StickyTabs = ( {title, tabs, extendToScreenEdge, container } ) => {
    const {currentTabId} = useContext(SmartPathContext);
    const [selectedTab, setSelectedTab] = useState(tabs[0].title);

    useEffect(() => {
        setOverflow('clip');

        return () => {
            setOverflow('hidden');
        };
    }, []);

    // This component leverages a css position property called 'sticky'. In order for this to work
    // there can be no parent components with the overflow property set to 'hidden'. This component
    // has logic to change an application level parent's overflow property to enable this. This has
    // beed implemented similarly on other places on Engage.
    const setOverflow = (property) => {
        document.getElementsByClassName("off-canvas-wrapper")[0].style.overflow = property;
    }

    const handleSideNavClickAnalytics = (tabName, container) => {
        clickAnalytics({
          category: currentTabId.replace(/[^\w\s]/g, ''),
          label: 'Side Nav Click',
          contentTitle: tabName,
          container: container
        });
    };

    const handleClick = (tabName, container) => {
        setSelectedTab(tabName);
        handleSideNavClickAnalytics(tabName, container);

        const isTopInViewport = (element) => {
            const rect = element.getBoundingClientRect();
            return rect.top >= 0 && rect.top <= window.innerHeight;
        };

        // Handles scrolling the element into view if it is rendered off screen
        setTimeout(() => {
            const clickedElement = document.getElementById(`tab-content-${title}-${tabName}`);
            const yPositionAfterMenuChange = clickedElement.getBoundingClientRect().top;
            const currentScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            if(clickedElement && !isTopInViewport(clickedElement)) {
                window.scrollTo ({
                    top: yPositionAfterMenuChange + currentScrollPosition - 130,
                    behavior: 'smooth'
                })
            }
        }, 1);
    }

    const StickyTab = ( {tabName, selected, container } ) => {
        return (
            <div className={`sticky-tab ${selected ? 'selected' : ''}`} onClick={() => handleClick(tabName, container)}>
                <div className="tab-label">{tabName}</div>
                {selected &&
                    <img 
                        className="tab-arrow"
                        src={`${AppSettings.AWSImgRepo.resources}en/sticky-tab-arrow.png`}
                        alt="arrow"
                    />
                }
            </div>
        );
    };

    return (
        <div className="sticky-tabs-component">
            <div className="component-headline">{title}</div>
            <div className={`sticky-tabs-container${extendToScreenEdge ? ' extend' : ''}`}>
                <div className="navigation-menu" id={`sticky-navigation-menu`}>
                    <div className="tab-container">
                        {tabs?.map(tab => 
                            <StickyTab
                                tabName={tab.title}
                                selected={selectedTab === tab.title}
                                container={container ? container : title}
                            />
                        )}
                    </div>
                </div>

                <div className={`sticky-tabs-content${extendToScreenEdge ? ' extend' : ''}`} id={`tab-content-${title}-${selectedTab}`}>
                    {tabs?.find(tab => tab.title === selectedTab)?.component}
                </div>
            </div>
        </div>
    );
};

export default StickyTabs;
