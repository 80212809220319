import React from "react";
import "../../../../styles/scss/smartpathV2/whatIsSmartPath/videoSpotlight.scss";
import VideoPlayerComponent from '../../../videoPlayer/videoPlayer.component';
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
import Appsettings from "../../../../appsettings.js";
import { openPdf, openLink } from "../../sharedComponents/utils.js";

const VideoSpotlight = ({ data, analyticsData }) => {

  const handleButtonClick = () => {
    handleButtonClickAnalytics();
    if (data.pdfName) {
      openPdf(data.pdfName);
      return;
    }

    if(data.linkName) {
      openLink(data.linkName);
      return;
    }
  };

  const handleButtonClickAnalytics = () => {
    clickAnalytics({
      category: 'What is SmartPath',
      label: 'CTA Click',
      contentTitle: analyticsData?.title,
      container: analyticsData?.container
    });
  };

  return (
    <div className="video-container">
      <div className="video-header">
        {data?.title}
        <div className="video-sub-header">
          <div className=" video-description-container">
            {data?.description}
          </div>
          <a className="video-button-container" alt="download" onClick={() => handleButtonClick()} href={data?.link}>
            {data?.buttonText}
          </a>
        </div>

      </div>
      <div className="video-content">
        {(data?.qumuId || data?.qumuId && data?.image) && <VideoPlayerComponent item={{ qumuId: data?.qumuId.toString() }} />}
        {(data?.image && (!data?.qumuId && data?.image)) && <img src={Appsettings.AWSImgRepo.smartPathV2 + data?.image} />}
      </div>
    </div>
  );
};

export default VideoSpotlight;