import React, { useEffect, useState } from "react";
import S from "../../../../styles/scss/smartpathV2/getStarted/smartPathEmail.module.scss";
import { postSmartPathEmail } from "../../../../services/emailService";
import appsettings from "../../../../appsettings";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
import { isDesktopView, isTabletView, isMobileView } from "../../../utils/getScreenSize";

// Utility function to get value from localStorage safely
const getLocalStorageValue = (key, defaultValue = "") => {
  try {
    return localStorage.getItem(key) || defaultValue;
  } catch (error) {
    console.error(`Error accessing localStorage for key ${key}:`, error);
    return defaultValue;
  }
};

const SmartPathEmailForm = ({ formLabels, services }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSubmittedContent, setIsFormSubmittedContent] = useState({ header: '', description: '' });
  const [formData, setFormData] = useState({
    dealership: "",
    dealerFirstName: getLocalStorageValue("firstName"),
    dealerLastName: getLocalStorageValue("lastName"),
    dealerPhoneNumber: "",
    dealerEmail: getLocalStorageValue("email"),
    message: "",
    selectedBoxes: [],
  });

  const getImage = (data) => {
    if (isDesktopView(window.innerWidth)) {
      return data.submissionConfirmationImageDesktop;
    }
    if (isTabletView(window.innerWidth)) {
      return data?.submissionConfirmationImageTablet;
    }
    if (isMobileView(window.innerWidth)) {
      return data?.submissionConfirmationImageMobile;
    }
    return undefined;
  }
  
  const handleFormChangeAnalytics = (section, content) => {
    clickAnalytics({
      category: 'Get Started',
      label: 'Form Content Change',
      container: section,
      contentTitle: content
    });
  };

  const handleSubmitFormAnalytics = (content) => {
    clickAnalytics({
      category: 'Get Started',
      label: 'Submit Form Button',
      contentTitle: content
    });
  };

  useEffect(() => {

    // Helper function to validate phone number (10 digits)
    const isValidPhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);

    // Helper function to validate email format
    const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    const isValid = (
      formData.dealership.trim() &&
      formData.dealerFirstName.trim() &&
      formData.dealerLastName.trim() &&
      formData.message.trim() &&
      formData.selectedBoxes.length > 0 &&
      isValidPhoneNumber(formData.dealerPhoneNumber) &&
      isValidEmail(formData.dealerEmail)
    );

    setIsFormValid(isValid);
  }, [formData]);

  const formatMessageBody = () => {
    const firstLine = `This is a contact request from ${formData.dealerFirstName} ${formData.dealerLastName} at the ${formData.dealership} dealership.`;
    const secondLine = `\nYou can reach ${formData.dealerFirstName} at ${formData.dealerEmail} or ${formData.dealerPhoneNumber}\n`;
    const questions = formData.message ? `\nHere are their question(s): \n${formData.message}` : '';

    const emailObject = {
      message: firstLine + secondLine + questions,
      recipients: formData.selectedBoxes.map(option => option.email),
    };

    return emailObject;
  };

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormChangeAnalytics(name, value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle selecting or deselecting a box
  const handleBoxSelect = (label) => {
    setFormData((prev) => {
      const selectedOption = services.find((option) => option.label === label);
      if (!selectedOption) {
        return prev;
      }

      const isSelected = prev.selectedBoxes.some((option) => option.label === label);
      return {
        ...prev,
        selectedBoxes: isSelected
          ? prev.selectedBoxes.filter((option) => option.label !== label)
          : [...prev.selectedBoxes, selectedOption],
      };
    });
  };
  
  const handleReset = () => {
    setIsFormSubmitted(false)
    setIsFormSubmittedContent({ header: '', description: '' });
    setFormData({
      dealership: "",
      dealerFirstName: getLocalStorageValue("firstName"),
      dealerLastName: getLocalStorageValue("lastName"),
      dealerPhoneNumber: "",
      dealerEmail: getLocalStorageValue("email"),
      message: "",
      selectedBoxes: [],
    });
  }

  const handleSubmit = async (e) => {
    handleSubmitFormAnalytics(e.target.textContent);
    console.log("Submitting Smartpath Application Form...");
    const emailObject = formatMessageBody();
    if (!isFormValid) {
     console.log("Please fill out all fields and select at least one inbox in order to submit");
     return;
    }

    try {
      await postSmartPathEmail(emailObject);
      setIsFormSubmittedContent({ header: formLabels?.submissionConfirmationHeader, description: formLabels?.submissionConfirmationDescription });
    } catch (error) {
      console.error("Error triggering the postSmartPathEmail endpoint", error);
      setIsFormSubmittedContent({ header: formLabels?.submissionErrorHeader, description: formLabels?.submissionErrorDescription });
    }
    setIsFormSubmitted(true);
  };

  return (
    <>
      <div className={S["email-form-container-wrapper"]} style={isFormSubmitted ? { display: 'none' } : {}}>
        <div className={S["email-form-container"]} style={isFormSubmitted ? { display: 'none' } : {}}>
          <div className={S["products-container"]}>
            <div className={S["products-header"]}>
              <h2 className={S["title"]}>{formLabels.header}</h2>
              <span className={S["text"]}>{formLabels.description}</span>
            </div>

            <div className={S["products-box"]}>
              <span className={S["selection-subtitle"]}>Select all that apply*</span>
              <div className={S["selectable-boxes"]}>
                {services.map((option) => (
                  <SelectableBox
                    key={option.label}
                    label={option.label}
                    selected={formData.selectedBoxes.some(item => item.label === option.label)}
                    onSelect={handleBoxSelect} />
                ))}
              </div>
            </div>
          </div>

          <div className={S["message-container"]}>
            <section>
              <div className={S["header-section"]}>
                <h2 className={S["title-format"]}>Dealer Information</h2>
                <span className={S["description-format"]}>Please complete all information below</span>
              </div>

              <div className={S["form-section"]}>
                <input
                  type="text"
                  name="dealership"
                  value={formData.dealership}
                  onChange={handleInputChange}
                  placeholder="Dealership*"
                  aria-label="Dealership Name" />
                <div className={S["input-row"]}>
                  <input
                    type="text"
                    name="dealerFirstName"
                    value={formData.dealerFirstName}
                    onChange={handleInputChange}
                    placeholder="First Name*"
                    aria-label="First Name" />
                  <input
                    type="text"
                    name="dealerLastName"
                    value={formData.dealerLastName}
                    onChange={handleInputChange}
                    placeholder="Last Name*"
                    aria-label="Last Name" />
                </div>
                <div className={S["input-row"]}>
                  <input
                    type="text"
                    name="dealerPhoneNumber"
                    value={formData.dealerPhoneNumber}
                    onChange={handleInputChange}
                    placeholder="Phone Number*"
                    aria-label="Phone Number" />
                  <input
                    type="email"
                    name="dealerEmail"
                    value={formData.dealerEmail}
                    onChange={handleInputChange}
                    placeholder="Email*"
                    aria-label="Email Address" />
                </div>
              </div>
            </section>

            <section>
              <div className={S["header-section"]}>
                <label htmlFor="message" className={S["title-format"]}>{formLabels.responsePrompt}</label>
                <span className={S["description-format"]}>{formLabels.responseDescription}</span>
              </div>

              <div className={S["form-section"]}>
                <textarea id="message" name="message" value={formData.message} onChange={handleInputChange} aria-label="Additional Comments" />
              </div>
            </section>

            <div className={S["button-section"]}>
              <button onClick={handleSubmit} disabled={!isFormValid} style={{ cursor: 'pointer' }}>
                Submit
              </button>
              <span>
                *Required Fields
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={S["submission-container-wrapper"]} style={!isFormSubmitted ? { display: 'none' } : {}}>
        <div className={S["submission-container"]} style={!getImage(formLabels) ? { display: 'none' } : {}}>
          <img src={appsettings.AWSImgRepo.smartPathV2 + getImage(formLabels)} />
          <div className={S["submission-text-container"]}>
            <div className={S["submission-title"]}>
              {isFormSubmittedContent?.header}
            </div>
            <div className={S["submission-description"]}>
              {isFormSubmittedContent?.description}
            </div>
            <div className={S["submission-reset-button-wrapper"]}>
              <button className={S["submission-reset-button"]} onClick={handleReset} disabled={!isFormSubmitted}>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SelectableBox = ({ label, selected, onSelect }) => {
  const checkSvg = appsettings.AWSImgRepo.resources + "en/smartPathCheck.svg";
    
  return (
    <button
      className={`${S["selectable-box"]} ${selected ? S["selected"] : ""}`}
      onClick={() => onSelect(label)}
      aria-pressed={selected}
      type="button"
    >
      <img src={checkSvg} alt="checkbox" />
      <span className={S["box-label"]}>{label}</span>
    </button>
  );
};

export default SmartPathEmailForm;
