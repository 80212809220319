import React, { useContext, useEffect, useState } from "react";
import { isDesktopView, isTabletView } from "../../utils/getScreenSize";
import AppSettings from "../../../appsettings";
import { ReactComponent as PlayButton } from "../../../resources/images/smartpath-header-play-button.svg";
import { SmartPathContext } from "./smartPathContext";
import "../../../styles/scss/smartpathV2/sharedComponents/smartpath-header.scss";
import { clickAnalytics } from "./smartpath.analytics";
import { getQumuSources } from "../../../services/videoLookupService";
import { ClipLoader } from "react-spinners";

const SmartPathHeader = ( {data, qumuId} ) => {
    const [thumbnailSrc, setThumbnailSrc] = useState(null);
    const [thumbnailLoading, setThumbnailLoading] = useState(true);
    const image = isDesktopView(window.innerWidth) ? data.backgroundImageDesktop : isTabletView(window.innerWidth) ? data.backgroundImageTablet : data.backgroundImageMobile;
    const smartPathContext = useContext(SmartPathContext);

    useEffect(() => {
        setThumbnailLoading(true);
        getQumuSources(qumuId)
            .then(data => {
                setThumbnailSrc(data.poster);
            })
    }, [qumuId]);

    //TODO: add qumu id to video once this implementation is complete
    const handleButtonClickAnalytics = (category, id) => {
        clickAnalytics({             
          category: category.replace(/[^\w\s]/g, ''),
          label: 'Header Video Open',
          contentTitle: id
        });
    }

    const handlePlayButtonClick = () => {
        handleButtonClickAnalytics(smartPathContext.currentTabId, data.videoModalId)
        smartPathContext.setVideoModalId(data.videoModalId);
    };

    return (
        <div className="smartpath-header">
            <img
                className="header-image"
                src={`${AppSettings.AWSImgRepo.smartPathV2}${image}`}
                alt="banner"
                draggable="false"
            />
            <div className={`content-container ${data.expandedTabletVideo? 'expanded-tablet' : ''}`}>
                <div className="headline-text">{data.headlineText}</div>
                <div className="description-text">{data.descriptionText}</div>
                {thumbnailLoading &&
                    <div className="thumbnail-skeleton">
                        <ClipLoader color={"#EB0A1E"}/>
                    </div>
                }
                <div className="video-thumbnail-container" style={thumbnailLoading ? {display: 'none'} : {}}>
                    <img
                        className="video-thumbnail-image"
                        src={thumbnailSrc}
                        alt="thumbnail"
                        draggable="false"
                        onLoad={() => setThumbnailLoading(false)}
                    />
                    <div className="video-tumbnail-info-container">
                        <PlayButton className="play-button-icon" onClick={() => handlePlayButtonClick()}/>
                        <div className="play-button-label">{data.videoPlayButtonText}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartPathHeader;
