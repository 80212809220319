import React from "react";
import appsettings from "../../../../appsettings";
import S from "../../../../styles/scss/smartpathV2/getStarted/smartPathPreflightChecklist.module.scss";
import { isDesktopView, isTabletView } from "../../../utils/getScreenSize";

const PreflightChecklist = ({data}) => {
  const image = isDesktopView(window.innerWidth) ? data.componentImageDesktop : isTabletView(window.innerWidth) ? data.componentImageTablet : data.componentImageMobile;
  const imagePath = appsettings.AWSImgRepo.smartPathV2 + image;

  return (
    <div className={S["preflight-checklist-container"]}>
      <img src={imagePath} alt="checklist" draggable="false"/>
      <div className={S["preflight-checklist-list"]}>
        {data.steps.map((step) => (
          <div
            className={`${S["preflight-checklist-item"]} ${step.completed ? S["preflight-checklist-item-completed"] : ""}`}
            style={{
              "--background-color": data.componentColor,
            }}
          >
            <div className={S["item-label"]}>{step.stepLabel}</div>
            <span className={S["item-title"]}>{step.stepTitle}</span>
            <span className={S["item-description"]}>{step.stepDescription}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PreflightChecklist;
