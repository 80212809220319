import React from "react";
import AppSettings from "../../../../appsettings";
import { useHistory } from "react-router";
import "../../../../styles/scss/smartpathV2/welcome/smartPathTiles.scss";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";

const SmartPathWelcomeTiles = ({ tiles }) => {

  const SmartPathWelcomeTile = ({ title, image, subtitle1, body1, subtitle2, body2, buttonText, buttonLink, buttonColor }) => {
    const history = useHistory();
  
    const handleButtonClick = (url, name) => {
      handleButtonClickAnalytics(name);
      history.push(url);
      window.scrollTo(0, 0);
    };

    const handleButtonClickAnalytics = (name) => {
      clickAnalytics({             
        category: 'Welcome',
        label: 'Tile Button Click',
        contentTitle: name 
      });
    };
  
    return (
      <div className="tile-container">
        <div className="tile-content">
          <h2 className="tile-title">{title}</h2>
          <img src={`${AppSettings.AWSImgRepo.smartPathV2}${image}`} alt={title} />
          <div className="info-container">
            <p className="tile-subtitle">{subtitle1}</p>
            <p className="tile-body">{body1}</p>
            <p className="tile-subtitle">{subtitle2}</p>
            <p className="tile-body">{body2}</p>
          </div>
        </div>
        <div
            onClick={() => handleButtonClick(buttonLink, buttonText)}
            className="tile-button"
            style={{ backgroundColor: buttonColor }}
          >
            {buttonText}
          </div>
      </div>
    );
  };

  return (
    <div className="welcome-tiles-container">
      {tiles.map((tile) => (
        <SmartPathWelcomeTile {...tile} />
      ))}
    </div>
  );
}

export default SmartPathWelcomeTiles;
