import React, { useState, useEffect } from 'react'
import { getProfileImageUrl } from '../utils/profileImageUrl';
import '../../styles/css/activityCommentCard.css'
import { myAccountSeeLessClick, myAccountSeeMoreClick } from './commentCard.analytics'
import appsettings from '../../appsettings';

const CommentCardComponent = (props) => {
    const [displayText, setDisplayText] = useState(props.comment?.comment);
    const [characterLimit, setCharacterLimit] = useState();
    const [expandable, setExpandable] = useState(props.comment?.comment.length > (characterLimit - 12));
    const [expanded, setExpanded] = useState();
    const commentDate = new Date(props.comment?.createdDate.replace(/-/g, '/'));
    const [pictureUrl, setPictureUrl] = useState(null);

    function expand(clickAnalytics, positionTitle) {
        setExpanded(true);
        //  analytics
        myAccountSeeMoreClick(clickAnalytics, positionTitle)
    }

    function collapse(clickAnalytics, positionTitle) {
        setExpanded(false);
        //  analytics
        myAccountSeeLessClick(clickAnalytics, positionTitle)
    }

    useEffect(() => {
        const fetchImages = async () => {
            if(localStorage.pictureUrl === props.comment?.userImage){
                const imageUrl = await getProfileImageUrl();
                setPictureUrl(imageUrl);
            }
        }
        fetchImages();
    }, []);

    useEffect(() => {
        updateCharacterLimit()
    }, []);

    useEffect(() => {
        let text;
        if (expandable && !expanded) {
            text = decodeURI(props.comment?.comment).substring(0, characterLimit) + '...';
        } else if (expandable && expanded) {
            text = decodeURI(props.comment?.comment);
        } else if (!expandable) {
            text = decodeURI(props.comment?.comment);
        }
        setDisplayText(text)
    }, [expandable, expanded]);

    useEffect(() => {
        setExpandable(props.comment?.comment.length > (characterLimit - 12));
    }, [characterLimit]);

    function updateCharacterLimit() {
        let limit;
        switch (props.device) {
            case 'desktop':
                limit = props.isReply ? 145 : 155;
                break;
            case 'tablet':
                limit = props.isReply ? 70 : 80;
                break;
            case 'mobile':
                limit = props.isReply ? 70 : 80;
                break;
        }
        setCharacterLimit(limit);
    }

    function getFormattedDate() {
        const formattedDate = commentDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: '2-digit' }).toUpperCase();

        // If it's a comment, show the date in bold
        if (!props.isReply) return <span className='bold-date'>{formattedDate}</span>;

        // If it's an unread reply, show 'New' in bold
        if (props.comment?.isUnread) return <span className='bold-date'>New</span>;

        const today = new Date();
        const daysSince = Math.round((today.getTime() - commentDate.getTime()) / (1000 * 60 * 60 * 24));
        // If it's a read reply, show the date, or number of days since the reply (if less than a week)
        if (daysSince < 7) {
            return daysSince === 0 ? 'Today' : `${daysSince} days ago`;
        } else {
            return formattedDate;
        }
    }

    function renderContent() {
        if (props.device === 'mobile') {
            return (
                <div className='comment-content'>
                    <div className='main-content'>
                        <div className='comment-info'>
                            <div className='image-date'>
                                {pictureUrl ? <img src={`data:image;base64, ${pictureUrl}`} /> : <img src={appsettings.AWSImgRepo.resources + `en/default.svg`}/>}
                                <div className='date'>{getFormattedDate()}</div>
                            </div>
                            <div className='name'>{props.comment?.userFirstName} {props.comment?.userLastName}</div>
                        </div>
                        <div className='text'>
                            {displayText}
                            {expandable && !expanded &&
                                <span className='see-more' onClick={() => (expand(props.analyticsData?.clickAnalytics, props.comment?.articleTitle ))}> See More</span>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='comment-content'>
                    {pictureUrl ? <img src={`data:image;base64, ${pictureUrl}`} /> : <img src={appsettings.AWSImgRepo.resources + `en/default.svg`}/>}
                    <div className='main-content'>
                        <div className='comment-info'>
                            <div className='name'>{props.comment?.userFirstName} {props.comment?.userLastName}</div>
                            <div className='date'>{getFormattedDate()}</div>
                        </div>
                        <div className='text'>
                            {displayText}
                            {expandable && !expanded &&
                                <span className='see-more' onClick={() => (expand(props.analyticsData?.clickAnalytics, props.comment?.articleTitle ))}> See More</span>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={`comment-container ${props.isReply && 'reply'}`}>
            {renderContent()}
            {expanded &&
                <a className='see-less' onClick={() => (collapse(props.analyticsData?.clickAnalytics, props.comment.articleTitle ))}> See Less</a>
            }
        </div>
    )
}

export default CommentCardComponent;