import React, { useEffect, useState } from "react";
import appsettings from "../../../../appsettings";
import S from "../../../../styles/scss/smartpathV2/resources/resourceSearchBar.module.scss";
import { clickAnalytics } from "../../sharedComponents/smartpath.analytics";
const searchSvg = appsettings.AWSImgRepo.resources + "en/smartPath-search-icon.svg";
const clearSvg = appsettings.AWSImgRepo.resources + "en/smartPath-clear-search.svg";

const ResourceSearchBar = ({ value, setValue, placeholder }) => {

  const [inputValue, setInputValue] = useState(value);

  const handleCloseAnalytics = () => {
    clickAnalytics({
      category: 'Resources-Release Notes',
      label: 'Clear Search'
    });
  };

  const handleClose = () => {
    setInputValue("");
    handleCloseAnalytics();
  };

  useEffect(() => {
    setInputValue(value)
  }, [value]);

  const handleChange = (event) => {
    const typedValue = event.target.value;
    setInputValue(typedValue);
    setValue(typedValue);
  }

  return (
    <div className={S["search"]}>
      <img className={S["search-icon"]} src={searchSvg} alt="search icon" />
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {value && <img className={S["close-icon"]} src={clearSvg} onClick={handleClose} alt="clear search icon" />}
    </div>
  );
};

export default ResourceSearchBar;
