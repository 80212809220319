import SmartPathWelcomeTiles from "./components/smartpathTiles.js";
import "../../../styles/scss/smartpathV2/welcome/smartPathWelcomePage.scss";

const SmartPathWelcome = ({ data }) => {

  return (
    <div className="smartpath-welcome-page">
      <SmartPathWelcomeTiles
        tiles={data?.welcomePageTiles}
      />
    </div>
  );
};

export default SmartPathWelcome;
