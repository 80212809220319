import PropTypes from 'prop-types';
import { Component } from 'react';
import getAnalytics from '../../services/analyticsService';
import * as analytics from './searchBar.analytics';
import { getFeatureSearchResults, getSearchResults } from '../../services/elasticsearchService';
import { searchProducts } from '../utils/searchProducts';
import NON_VEHICLE_SEARCH_DATA from './non-vehicle-search.js';
import SearchBarTemplate from './searchBar.tpl';
import appSettings from '../../appsettings.js';
import getNavigation from '../../services/navigationService'
import DropdownItems from '../topNav/dropdownMenu/dropdownItems';
import getAlternativeVehicleNames from '../../services/alternativeVehicleNames';
import getVehicleOverview from '../../services/vehicleOverviewService';
import { getDynamoName } from '../utils/vehicleName';
import { filter, reject } from 'async';
import { isDesktopView, isTabletView, isMobileView } from '../../screens/utils/getScreenSize';
import { postEngageSearchAction } from '../../services/nitroService';
import {cleanString as cleanStringFunc} from '../utils/helpers.js'
export default class SearchBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightItems: {},
      articleResults: [],
      totalResults: 0,
      searchResults: {},
      nonVehicleSearchResults: null,
      suggestedProduct: null,
      searchParam: new URLSearchParams(window.location.search).get('q') === null ? '' : cleanStringFunc(new URLSearchParams(window.location.search).get('q')),
      pageNumber: 0,
      firstOnPage: 0,
      activeFilter: 'All',
      currentVehicle: null,
      currentVehicleType: null,
      knowledgePaneFlag: false,
      quickAnswersFlag: false,
      currentVehicleDetails: null,
      analyticsData: null,
      isSearching: true,
      device: "desktop",
      isAdvancedSearch: false,
      AdvancedSearchParamObject: {},
      productionStatus:{},
      innerWidth: 0
    };

    this.resultsPerPage = 8;
    this.scroll = 0;
    this.scrollLeft = 0;
    this.disclaimers = [];
    this.disclaimerCallback = this.disclaimerCallback.bind(this);
    this.queryElasticsearch = this.queryElasticsearch.bind(this);
    this.isColumnActive = this.isColumnActive.bind(this);
    this.reRenderHeight = this.reRenderHeight.bind(this);
    this.getSearchAnalytics = this.getSearchAnalytics.bind(this);
    this.runSearch = this.runSearch.bind(this);
    this.runAdvancedSearch = this.runAdvancedSearch.bind(this);
    this.filterHighlightItems = this.filterHighlightItems.bind(this);
  }

  updateDevice = (e) => {
    const screenWidthNotChanged = this.state.innerWidth === window.innerWidth;
    if (screenWidthNotChanged) return;

    if (isDesktopView(window.innerWidth)) {
      this.setState({ device: "desktop", innerWidth: window.innerWidth})
    } else if (isTabletView(window.innerWidth)) {
      this.setState({ device: "tablet", innerWidth: window.innerWidth})
    } else if (isMobileView(window.innerWidth)) {
      this.setState({ device: "mobile", innerWidth: window.innerWidth})
    }
  }

  async componentDidMount() {
    await this.checkModelCode()
    postEngageSearchAction(cleanStringFunc(this.state.searchParam));
    this.runSearch(cleanStringFunc(this.state.searchParam));
    this.getVehicles(cleanStringFunc(this.state.searchParam));
    this.getSearchAnalytics();

    this.updateDevice();
    window.addEventListener("resize", this.updateDevice);
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.updateDevice);
  }


  async getSearchAnalytics() {
    const data = await getAnalytics('search')
    this.setState({
      analyticsData: data,
    });
  }

  onLoadAnalytics() {
    if (this.state.analyticsData) {
      const description = this.getPageLoadDescription();
      const keyword = this.state.searchParam;
      const numberOfSearchResults = this.state.articleResults.length.toString();

      analytics.searchLoaded(this.state.analyticsData.pageLoad, description, keyword, numberOfSearchResults);
    }
  }

  getPageLoadDescription() {
    if (this.state.knowledgePaneFlag && this.state.quickAnswersFlag) {
      return this.state.analyticsData.pageLoad.descEnhancedBoth;
    } else if (this.state.knowledgePaneFlag) {
      return this.state.analyticsData.pageLoad.descEnhancedKnowledgePane;
    } else if (this.state.quickAnswersFlag) {
      return this.state.analyticsData.pageLoad.descEnhancedRichAnswers;
    } else {
      return this.state.analyticsData.pageLoad.descSimple;
    }
  }

  componentDidUpdate() {
    if (document.querySelector(".filter-tabs-mobile")) document.querySelector(".filter-tabs-mobile").scrollLeft = this.scrollLeft;
    this.scroll = 0;
    this.scrollLeft = 0;
    this.clickPaginationButtonAnalytcis()
  }

  clickPaginationButtonAnalytcis() {
    const buttons = document.getElementsByClassName("paginationBtns")
    if (buttons && buttons[0] && buttons[0].childNodes) {
      Array.from(buttons[0].childNodes).forEach(child => {
        child.addEventListener('click', () => {
          let contentTitle = child.childNodes[0].innerHTML;
          if (contentTitle !== "Next" && contentTitle !== "Previous") {
            contentTitle = "Page " + contentTitle;
          }
          const label = contentTitle;
          if (this.state.analyticsData) {
            analytics.paginationButtonClicked(
              this.state.analyticsData.clickAnalytics,
              this.state.analyticsData.clickAnalytics.paginationButtons.positionTitle + contentTitle,
              label,
              this.state.searchParam,
              this.state.articleResults.length.toString(),
            );
          }
        })
      })
    }
  }

  disclaimerCallback(index, resultIndex) {
    this.disclaimers[resultIndex].onOpenModal(index);
  }

  checkModelCode = async () =>{

    try {
      const searchItems = await  getFeatureSearchResults('*', this.createFuzzySearchString(), this.state.firstOnPage, 1000)

      let newsearchterm = ""
      let Content = searchItems.featureBody.hits.hits
      if (Content && Content[0]._source.type == "modelCode"){
        newsearchterm = Content[0]._source.displayModelName + ' ' + this.state.searchParam
        this.state.searchParam = newsearchterm
      }
    }catch(e){
      console.log(e)
    }
  }

  checkVehicleList = (vehicleList, isProd,searchParam) => {
    if (!this.state.currentVehicle) {
      vehicleList.forEach(vehicle => {
        const allNames = Array.isArray(vehicle.allNames) ? vehicle.allNames : [vehicle.allNames]
        allNames.forEach(vehicleName =>{
        if (new RegExp(`\\b${vehicleName.toLowerCase()}\\b`).test(searchParam.toLowerCase())) {
          this.setState({
            currentVehicle: vehicle,
            currentVehicleType: isProd,
            knowledgePaneFlag: true,
          })
          getVehicleOverview(getDynamoName(vehicle.model), vehicle.modelYear, false, 'en')
            .then((data) => {
              if (data) {
                this.setState({ currentVehicleDetails: data})
              }
            })
        }
      })
      })
    }
  }

  filterHighlightItems() {
    const filteredHighlightItems = this.state.highlightItems.filter(
      item => item._source.displayModelName.toLowerCase() === this.state.currentVehicle.model.toLowerCase()
      && (item._source.descriptionToShow !== 'Model Code' ? item._source.year == this.state.currentVehicle.modelYear : true)
    );
    this.setState({
      highlightItems: filteredHighlightItems,
    });
  }

  checkKnowledgePaneFlag() {
    if(this.state.isAdvancedSearch){
      this.setState({
        knowledgePaneFlag: false,
      });
    }
    else if (this.state.currentVehicle) {
      this.setState({
        knowledgePaneFlag: true,
      });
    }
  }

  checkQuickAnswerFlag() {
    if(this.state.isAdvancedSearch){
      this.setState({
        quickAnswersFlag: false,
      });
    }
    else if (this.state.highlightItems && this.state.highlightItems.length > 0) {
      const quickAnswerTypes = ['feature', 'groupFeature', 'spec', 'msrp', 'tss', 'colorAvailability', 'colorByTrim', 'packageByTrim', 'modelCode']
      if (quickAnswerTypes.includes(this.state.highlightItems[0]._source.type)) {
        this.setState({
          quickAnswersFlag: true,
        });
      }
    }
  }

  getCurrentVehicle(vehicleLists,searchParam) {
    if (!this.state.currentVehicle) {
      Object.values(vehicleLists).forEach((vehicleList, index) => {
        const isProd = index === 0 ? true : false;
        this.checkVehicleList(vehicleList.filter(vehicle => vehicle.model.split(' ').length > 1), isProd,searchParam);
      })
      Object.values(vehicleLists).forEach((vehicleList, index) => {
        const isProd = index === 0 ? true: false;
        this.checkVehicleList(vehicleList.filter(vehicle => vehicle.model.split(' ').length === 1), isProd,searchParam);
      })
    }
  }

  getVehicles = () => new Promise((resolve, reject) => {
    getNavigation('mainMenu')
    .then((mainMenuNavigation) => {
      let prodStatus = {
        production: [],
        nonproduction: [],
      }
      const items = mainMenuNavigation.dropDownItems[3].menuItems;
      items.forEach((item, index) => {
        if (index < 3) {
          item.menuItems.forEach(menuItem => {
            menuItem["allNames"] = [];
            prodStatus.production.push(menuItem)
          })
        } if(index === 4) {
          item.menuItems.forEach(menuItem => {
            menuItem["allNames"] = [];
            prodStatus.nonproduction.push(menuItem)
          })
        } else {
        }
      })
      this.setState({productionStatus: prodStatus});
    }).then((value)=>{
        getAlternativeVehicleNames().then((allVehicleNames) => {
          console.log('allVehicleNames', allVehicleNames)
          const newProductionStatus = JSON.parse(JSON.stringify(this.state.productionStatus))
          allVehicleNames.Items.forEach((allVehiclesItem,index) => {
            const formattedName = allVehiclesItem.name.toLowerCase().slice(0, -3);
            const alternateNames = allVehiclesItem.altVehicleName;
            this.state.productionStatus.nonproduction.forEach((prodStatusItem, index) =>{
              if(formattedName === prodStatusItem.model.toLowerCase()){
                newProductionStatus.nonproduction[index].allNames = alternateNames;
              }
            })
            this.state.productionStatus.production.forEach((prodStatusItem,index) =>{
              if(formattedName === prodStatusItem.model.toLowerCase()){
                newProductionStatus.production[index].allNames = alternateNames;
              }
            })
          });
          this.getCurrentVehicle(newProductionStatus, this.state.searchParam);
          this.checkKnowledgePaneFlag();
        });
      }).catch((error) => {
        reject(error);
      });
  })

  reRenderHeight() {
    if (this.carousel) {
      this.carousel.forceUpdate();
    }
  }

  queryElasticsearch(searchString,AdvancedSearchFlag) {
    if(this.state.searchResults.hits !== null && AdvancedSearchFlag === true){
      this.setState({
        isSearching: true,
        isAdvancedSearch: true,
        highlightItems: {},
        searchResults: {},
        totalResults: 0,
        articleResults: [],
      })
    }
    getFeatureSearchResults('*',AdvancedSearchFlag?searchString:this.createFuzzySearchString(searchString), this.state.firstOnPage, 1000,AdvancedSearchFlag)
      .then((searchItems) => {
        this.setState({
          isSearching: false,
          highlightItems: (searchItems.featureBody && searchItems.featureBody.hits && searchItems.featureBody.hits.hits) ? searchItems.featureBody.hits.hits : {},
          searchResults: searchItems.mainSearchBody.hits,
          totalResults: searchItems.mainSearchBody.hits.total,
          articleResults: searchItems.mainSearchBody.hits.hits,
        });
        this.filterHighlightItems();
        this.checkQuickAnswerFlag();
        this.onLoadAnalytics();
      }).catch((error) => {
        this.setState({
          isSearching: false
        });
        console.log(error);
      });
  }

  static getSuggestedProduct(searchString) {
    const results = searchProducts(searchString);
    let suggestedProduct;
    if (results.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      suggestedProduct = results[0];
    }
    return suggestedProduct;
  }

  createFuzzySearchString() {
    return this.state.searchParam.replace(/[^a-zA-Z0-9\-()/\s]/gi, '').trim();
  }

  getMoreResults() {
    getFeatureSearchResults('*', this.createFuzzySearchString(), this.state.articleResults.length, this.state.resultsPerPage)
      .then((searchItems) => {
        this.setState({
          searchResults: searchItems.mainSearchBody.hits,
          articleResults: this.state.articleResults.concat(searchItems.mainSearchBody.hits.hits),
        });
      }).catch((error) => {
        console.log(error);
      });

    SearchBarComponent.sendClickAnalytics(' ', 'More Results');
  }

  static isValidSearchString(searchString) {
    let characterCount = 0;
    for (let i = 0; i < searchString.length; i++) {
      if (searchString[i] !== ' ') {
        characterCount++;
      }
    }

    return characterCount > 1;
  }

  runSearch(value) {
    // Remove non-alphanumeric characters and trailing whitespace
    this.getNonVehicleResults(value);
    const alphaNumString = value.replace(/[^a-zA-Z0-9\-()/\s]/gi, '').trim();
    if (SearchBarComponent.isValidSearchString(alphaNumString)) {
      this.queryElasticsearch(alphaNumString,false);
    }
  }
  runAdvancedSearch(AdvancedSearchParam) {
    if (Object.keys(AdvancedSearchParam).length != 0){
      this.setState({
        AdvancedSearchParamObject : AdvancedSearchParam,
      })
      // Remove Any quotes,or conjunctions along with special charaters
      this.checkKnowledgePaneFlag();
      this.checkQuickAnswerFlag();
      const cleanString = this.removeCharacters(AdvancedSearchParam)
      this.queryElasticsearch(cleanString,true);
      this.getVehicles(cleanString);
    }
  }

  removeCharacters(AdvancedSearchObject){
    var value
    var CleanSearchObject = AdvancedSearchObject
    for(var i in AdvancedSearchObject){
      if(!i.includes("Date")){
        value = cleanStringFunc(AdvancedSearchObject[i]);
      }
      else{
        value = AdvancedSearchObject[i];
      }
      CleanSearchObject[i]=value
    }
    return CleanSearchObject
  }

  getNonVehicleResults(str) {
    try {
      if (str) {
        const data = JSON.parse(JSON.stringify(NON_VEHICLE_SEARCH_DATA));
        let searchTerm = str.trim();
        searchTerm = searchTerm.toLowerCase();
        searchTerm = searchTerm.replace(/\s\s+/g, ' '); // replace all spaces with one space
        searchTerm = searchTerm.replace(/ /g, '_'); // replace spaces with underscore
        const pageName = data.synonymsMapping[searchTerm];
        if (pageName) {
          const page = data.pages[pageName];
          const tabs = [];
          for (const index in page.subTabs) {
            const subTab = `${pageName}_${page.subTabs[index]}`;
            if (this.isUserAuthorized(searchTerm, data.subTabs[subTab])) {
              tabs.push(data.subTabs[subTab]);
            }
          }
          const searchResult = {
            page,
            tabs,
          };
          this.setState({
            nonVehicleSearchResults: searchResult,
          });
        } else {
          this.setState({
            nonVehicleSearchResults: null,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  isUserAuthorized(searchTerm, tab) {
    if (tab.conditional && tab.conditional === true) {
      if (searchTerm.indexOf('smart') > -1) {
        return true;
      }
      return false;
    }
    return true;
  }

  isSearchResultsPageActive() {
    return (this.state.searchResults.hits !== undefined);
  }

  isColumnActive() {
    return (this.state.searchResults.hits !== undefined && this.state.searchResults.hits.length > 0);
  }

  render() {
    return SearchBarTemplate(this);
  }
}

SearchBarComponent.propTypes = {
  searchString: PropTypes.string,
  updateResults: PropTypes.bool,
  handeResultsUpdate: PropTypes.func,
  isSearching: PropTypes.bool,
};
